import { apiRoutes } from "../../api";

export const GoogleLogin = () => {

  const handleButtonClick = () => {
    window.open(apiRoutes.googleLogin, '_blank')
  }

  return <div className="flex items-center justify-center dark:bg-gray-800 mt-8 mx-4 rounded-lg">
      <button onClick={handleButtonClick} className="px-4 py-2 flex gap-2 rounded-lg text-slate-700 dark:text-slate-200 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150">
        <img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo"/>
        <span>Login with Google</span>
      </button>
    </div>
}