import "./index.css";

const getClass = (size, className) => {
  switch (size) {
    case "l":
      return `${className} ${className}__large`;
    case "xl":
      return `${className} ${className}__xlarge`;
    case "sm":
      return `${className}`;

    default:
      break;
  }
};

const getIcon = (type, size) => {
  switch (type) {
    case "plus":
      return (
        <svg
          className={getClass(size, "plus-minus-icon")}
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 45.402 45.402"
          xmlSpace="preserve"
        >
          <g>
            <path
              d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141
  c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27
  c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435
  c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z"
            />
          </g>
        </svg>
      );
    case "minus":
      return (
        <svg
          className={getClass(size, "plus-minus-icon")}
          fill="#000000"
          height="800px"
          width="800px"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 310.285 310.285"
          xmlSpace="preserve"
        >
          <path
            d="M155.143,0.001C69.597,0.001,0,69.597,0,155.143c0,85.545,69.597,155.142,155.143,155.142s155.143-69.597,155.143-155.142
	C310.285,69.597,240.689,0.001,155.143,0.001z M244.143,171.498c0,4.411-3.589,8-8,8h-163c-4.411,0-8-3.589-8-8v-32
	c0-4.411,3.589-8,8-8h163c4.411,0,8,3.589,8,8V171.498z"
          />
        </svg>
      );
      default:
        break;
  }
};

export function PlusAndMinusIcon({ size, type = "plus" }) {
  return (
    <div className={getClass(size, "plus-icon-container")}>
      {getIcon(type, size)}
    </div>
  );
}
