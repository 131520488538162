import './index.css';
import { Trash2, Upload } from "react-feather";
import {
  Button,
  Loader,
  Price,
  SelectField,
  Typography,
} from "../../../component";
import { openInputFile, toastUtil } from "../../../utils";
import { useFormSchema } from "./form-schema";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { useTransactions } from "../../../common";
import {
  SUPPORTED_SOURCE_LANGUAGES,
  SUPPORTED_TARGET_LANGUAGES,
} from "../../../core/core.constant";

export const UploadForm = ({ onSuccess }) => {
  const { schema } = useFormSchema();
  const { mutate, isLoading } = useTransactions();
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      items: [],
    },
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
  } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
    rules: {
      required: true,
    },
  });

  const language = watch("language");
  const targetLanguages = watch("targetLanguages");

  const onSubmit = async (values) => {
    mutate(values.items, {
      onSuccess: (response) => {
        onSuccess(response.data);
      },
      onError: () =>
        toastUtil({
          message: "Error occurred. Please try again",
          theme: "failure",
        }),
    });
  };

  const handleFileUpload = (files) => {
    const fileNameExists = fields.some((currentFile) =>
      files.some((file) => file.name === currentFile.name)
    );

    if (fileNameExists) {
      return toastUtil({
        message: "File already selected. Select another",
        theme: "failure",
      });
    }

    files.forEach((file) => {
      append({ file, language: null, targetLanguages: [] });
    });
  };

  const deleteFile = (fileIndex) => {
    remove(fileIndex);
  };

  const show = {
    selectField: fields.length === 0,
    appendMore: fields.length >= 1,
  };

  return (
    <div className={classNames("w-full h-[100vh] overflow-y-scroll pb-[10em]", {
      // "py-[10em]": fields.length
    })}>
      <FormProvider {...form}>
        {show.appendMore && (
          <Button
            disabled={isLoading}
            size="lg"
            onClick={() => openInputFile(handleFileUpload)}
            className="w-full md:w-48 mt-4 mb-[1em]"
            variant="secondary"
            translationKey="CREATE_TRANSCRIPTION.FORM.APPEND_MORE"
          />
        )}
        <form className="md:flex-row flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="md:w-[60%] w-full">
            <div className="flex flex-col">
              <Typography
                className="text-md md:text-lg"
                translationKey="CREATE_TRANSCRIPTION.HEADER"
              />
              {show.selectField && (
                <div
                  onClick={() => !isLoading && openInputFile(handleFileUpload)}
                  className="flex-col gap-4 mt-4 border border-dashed w-full flex justify-center items-center h-[150px] border-gray-300 rounded-xl"
                >
                  <button className="border-0 flex flex-col justify-center items-center">
                    <Upload size={25} />
                    <Typography
                      translationKey="UPLOAD.CLICK_TO_UPLOAD"
                      variant="h4"
                    />
                    <Typography
                      translationKey="UPLOAD.UPLOAD_CAPTION"
                      className="text-gray-600"
                      variant="h4"
                    />
                  </button>
                </div>
              )}

              {fields.map((file, index) => (
                <div
                  key={file.id}
                  className="border px-4 rounded-lg mb-6 last:mb-[100em]"
                >
                  <div className="flex justify-between items-center">
                    <div
                      className={classNames(
                        "mt-4 gap-1 border flex w-full md:w-auto items-center border-gray-300 rounded-xl"
                      )}
                    >
                      <div className="rounded-tl-lg rounded-bl-lg w-6 py-4 bg-gray-200">
                        <div className="text-sm text-center">{index + 1}</div>
                      </div>
                      <Typography
                        translationKey="UPLOAD.FILE_NAME"
                        values={{ fileName: file.file.name }}
                        variant="label"
                        className="px-2"
                      />
                    </div>
                    <Button
                      onClick={() => deleteFile(index)}
                      startIcon={<Trash2 size={16} />}
                      className="p-2 bg-gray-100 hidden md:flex"
                      translationKey="CREATE_TRANSCRIPTION.FORM.REMOVE"
                    />
                  </div>

                  <SelectField
                    name={`items.${index}.language`}
                    className="mt-6 rounded-lg outline-none w-full"
                    label="UPLOAD.FIELDS.SOURCE_LANG"
                    selectClassName="bg-gray-50"
                    placeholder="CREATE_TRANSCRIPTION.FORM.SOURCE_LANGUAGE_PLACEHOLDER"
                    options={SUPPORTED_SOURCE_LANGUAGES}
                    disabled={isLoading}
                    existingOptions={language}
                    error={errors?.items?.[index]?.language?.message}
                    control={control}
                    required
                  />
                  <SelectField
                    name={`items.${index}.targetLanguages`}
                    className="mt-4 rounded-lg outline-none w-full"
                    label="UPLOAD.FIELDS.TARGET_LANG"
                    placeholder="CREATE_TRANSCRIPTION.FORM.TARGET_LANGUAGE_PLACEHOLDER"
                    selectClassName="bg-gray-50"
                    isMultiple
                    disabled={isLoading}
                    existingOptions={targetLanguages}
                    options={SUPPORTED_TARGET_LANGUAGES}
                    error={errors?.items?.[index]?.targetLanguages?.message}
                    control={control}
                    required
                  />
                  <Button
                    onClick={() => deleteFile(index)}
                    startIcon={<Trash2 size={16} />}
                    className="mb-4 mt-2 w-full p-2 bg-gray-100 md:hidden"
                    translationKey="CREATE_TRANSCRIPTION.FORM.REMOVE"
                  />
                </div>
              ))}

              {isLoading && (
                <div className="flex justify-center md:justify-start">
                  <Loader size={30} />
                </div>
              )}
              <div className="md:flex hidden gap-4 flex-end w-full">
                <Button
                  disabled={!isValid || isLoading}
                  type="submit"
                  size="lg"
                  className="w-full md:w-48 mt-4"
                  variant="blue"
                  translationKey="CREATE_TRANSCRIPTION.FORM.CREATE"
                />
              </div>
            </div>
          </div>
          <div className="md:w-[40%] w-full flex justify-center">
            <Price
              isLoading={isLoading}
              isValid={isValid}
              durations={fields.reduce(
                (accumulator, currentValue) =>
                  accumulator + (currentValue?.file?.duration / 60 || 0),
                0
              )}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
