import Swal from "sweetalert2";
import { NoJobs } from "./no-job.component";
import { useAcceptJob, useGetJobs } from "../../../common/query";
import { JobListContent } from "./jobs-list-content.component";
import { Loader } from "../../../component";
import { formatMinutes, toastUtil } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../constants";

export const JobList = () => {
  const { data, isLoading, isError } = useGetJobs();
  const {
    mutate,
    isLoading: acceptingJob,
    error,
  } = useAcceptJob();

  const jobs = data?.jobs;

  const navigate = useNavigate();

  const show = {
    noJobs: !isLoading && !isError && jobs?.length === 0,
    loader: isLoading || acceptingJob,
    error: isError,
    jobs: !isLoading && !isError && !!jobs.length,
  };

  const acceptJob = async (duration, id) => {
    const { isConfirmed } = await Swal.fire({
      title: "Are you sure?",
      text: `Accepting this job means you're willing to dedicate the next ${formatMinutes(
        duration * 2
      )} to work on it`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I accept!",
    });
    if (isConfirmed) {
      sendRequestToAcceptJob(id);
    }
  };

  const sendRequestToAcceptJob = (id) => {
    mutate(id, {
      onSuccess: () => {
        toastUtil({
          message: "Accepted job",
          theme: "success",
          position: "top-center",
        });
        navigate(`${ROUTE_PATHS.JOBS.LIST}/${id}`);
      },
      onError: () => {
        toastUtil({
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : "Could not accept job",
          theme: "failure",
          position: "top-center",
        });
      },
    });
  };
  return (
    <>
      {show.loader && (
        <div className="my-4 flex flex-col items-center">
          <Loader size={30} />
        </div>
      )}
      {show.noJobs && <NoJobs />}
      {show.jobs && !show.loader && (
        <JobListContent
          jobs={jobs}
          isAvailableJobs={true}
          acceptJob={acceptJob}
        />
      )}
    </>
  );
};
