import { useMutation, useQuery } from "react-query";
import { apiRoutes, axiosInstance } from "../../api";
import { mapFiles } from "../../utils";

export const useLogin = () => {
  return useMutation(async ({ email, password }) => {
    const { data } = await axiosInstance.post(apiRoutes.login, {
      email,
      password,
    });
    return data;
  });
};
export const useRegister = () => {
  return useMutation(async ({ email, password, firstName, lastName }) => {
    const { data } = await axiosInstance.post(apiRoutes.register, {
      email,
      password,
      firstName,
      lastName,
    });
    return data;
  });
};

export const useTransactions = () => {
  return useMutation(async (values) => {
    const mappedValues = values.map((value) => {
      return {
        file: value.file,
        language: [value.language.label],
        targetLanguages: value.targetLanguages.map((lang) => lang.label),
      };
    });
    const mappedFiles = await Promise.all(
      mappedValues.map((file) => mapFiles(file))
    );
    const { data } = await axiosInstance.post(
      apiRoutes.transactions,
      mappedFiles
    );
    return data;
  });
};

export const useGoogleLogin = () => {
  return useMutation(async () => {
    const { data } = await axiosInstance.get(apiRoutes.googleLogin);
    return data;
  });
};


export const useGetJobs = (owner) => {
  return useQuery({
    queryFn: async () => {
      const {
        data: { data: transcription },
      } = await axiosInstance.get(
        `${apiRoutes.jobs}${owner ? "?owner=true" : ""}`
      );
      return transcription;
    },
  });
};

export const useGetJob = (id) => {
  return useQuery({
    queryFn: async () => {
      const {
        data: { data: job },
      } = await axiosInstance.get(`${apiRoutes.jobs}/${id}`);
      return job;
    },
  });
};

export const useAcceptJob = () => {
  return useMutation(async (id) => {
    return await axiosInstance.get(`${apiRoutes.jobs}/${id}/accept`);
  });
};

export const useSubmitJob = () => {
  return useMutation(async (id) => {
    return await axiosInstance.get(`${apiRoutes.jobs}/${id}/submit`);
  });
};
