import {
  FormattedDate,
  FormattedRelativeTime,
  FormattedTime,
} from 'react-intl';

export const breakElement = () => <br />;

export const italicElement = (chunk) => (
  <span className="italic">{chunk}</span>
);

export const boldElement = (chunk) => (
  <span className="font-semibold">{chunk}</span>
);

export const mutedElement = (chunk) => (
  <span className="text-neutral-400">{chunk}</span>
);

export const accentElement = (chunk) => (
  <span className="text-orange-300">{chunk}</span>
);

export const primaryElement = (chunk) => (
  <span className="text-blue-300">{chunk}</span>
);

export const destructiveElement = (chunk) => (
  <span className="text-red-300">{chunk}</span>
);

export const successElement = (chunk) => (
  <span className="text-green-300">{chunk}</span>
);

const getTime = (date) =>
  typeof date === 'string' ? new Date(date).getTime() : null;

const getTimeDiffFromNow = (date) => {
  const dateTime = getTime(date);

  return dateTime && (dateTime - Date.now()) / 1000;
};

export const relativeTimeElement = (chunk) => {
  const firstchunk = chunk?.[0];
  const timeInMillisecond = getTimeDiffFromNow(firstchunk);

  if (!timeInMillisecond) {
    return chunk;
  }

  return (
    <FormattedRelativeTime
      numeric="auto"
      value={timeInMillisecond}
      updateIntervalInSeconds={1}
    />
  );
};

export const dateElement = (chunk) => {
  const firstchunk = chunk?.[0];
  const time = getTime(firstchunk);

  if (!time) {
    return chunk;
  }

  return (
    <FormattedDate value={time} year="numeric" month="short" day="2-digit" />
  );
};

export const timeElement = (chunk) => {
  const firstchunk = chunk?.[0];
  const time = getTime(firstchunk);

  if (!time) {
    return chunk;
  }

  return <FormattedTime value={time} />;
};

export const datetimeElement = (chunk) => {
  const firstchunk = chunk?.[0];
  const time = getTime(firstchunk);

  if (!time) {
    return chunk;
  }

  return (
    <>
      <FormattedDate value={time} year="numeric" month="short" day="2-digit" />
      , <FormattedTime value={time} />
    </>
  );
};