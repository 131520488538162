import { IntlProvider } from 'react-intl';
import _noop from 'lodash/noop';

import { DEFAULT_LOCALES } from './i18n.constant';
import { flattenMessages } from './i18n.util';
import {
  accentElement,
  boldElement,
  breakElement,
  dateElement,
  datetimeElement,
  destructiveElement,
  italicElement,
  mutedElement,
  primaryElement,
  relativeTimeElement,
  successElement,
  timeElement,
} from './i18n-element.component';

export const I18n = ({
  language,
  locales,
  children,
}) => {
  const supportedLanguages = Object.keys(locales);
  const currentLocale = supportedLanguages.some((l) => l === language)
    ? language
    : ('en');

  const mergedMessages =
    currentLocale in DEFAULT_LOCALES
      ? {
          ...DEFAULT_LOCALES[currentLocale],
          ...locales[currentLocale],
        }
      : locales[currentLocale];

  return (
    <IntlProvider
      locale={currentLocale}
      messages={flattenMessages(mergedMessages)}
      defaultLocale={currentLocale}
      defaultRichTextElements={{
        b: boldElement,
        i: italicElement,
        br: breakElement,
        muted: mutedElement,
        accent: accentElement,
        primary: primaryElement,
        success: successElement,
        destructive: destructiveElement,
        relativeTime: relativeTimeElement,
        datetime: datetimeElement,
        date: dateElement,
        time: timeElement,
      }}
      // Suppress warning about pre compiling messages when running unit tests:
      // [@formatjs/intl] "defaultRichTextElements" was specified but "message" was not pre-compiled.
      // Please consider using "@formatjs/cli" to pre-compile your messages for performance.
      // For more details see https://formatjs.io/docs/getting-started/message-distribution
      onWarn={_noop}
    >
      {children}
    </IntlProvider>
  );
}