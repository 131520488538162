export function convertSecondsToTime(seconds, showMilliseconds = true) {
  // Separate seconds into whole seconds and milliseconds
  let wholeSeconds = Math.floor(seconds);
  let milliseconds = Math.round((seconds - wholeSeconds) * 1000);

  // Convert whole seconds into hours, minutes, and seconds
  let hours = Math.floor(wholeSeconds / 3600);
  let remainingSeconds = wholeSeconds % 3600;
  let minutes = Math.floor(remainingSeconds / 60);
  remainingSeconds = remainingSeconds % 60;

  // Format milliseconds
  let formattedMilliseconds = String(milliseconds).padStart(3, "0");

  // Format time components into desired format
  let formattedTime =
    String(hours).padStart(2, "0") +
    ":" +
    String(minutes).padStart(2, "0") +
    ":" +
    String(remainingSeconds).padStart(2, "0") +
    (showMilliseconds ? "," + formattedMilliseconds : "");

  return formattedTime;
}

export const convertTimeToSeconds = (timeStr) => {
  if (!timeStr) {
    return "";
  }
  const [hours, minutes, rest] = timeStr.toString().split(":");

  const [seconds, milliseconds] = rest?.split(",");

  // Convert each part to seconds
  const hoursInSeconds = parseInt(hours, 10) * 3600; // 1 hour = 3600 seconds
  const minutesInSeconds = parseInt(minutes, 10) * 60; // 1 minute = 60 seconds
  const secondsInSeconds = parseInt(seconds, 10); // seconds part
  const millisecondsInSeconds = parseInt(milliseconds, 10) / 1000; // 1 millisecond = 0.001 seconds

  // Sum all parts to get the total seconds
  const totalSeconds =
    hoursInSeconds +
    minutesInSeconds +
    secondsInSeconds +
    millisecondsInSeconds;

  return totalSeconds;
};

export const validateTimeFormat = (time = "") => {
  const splitTime = time.split(":");

  if (splitTime.length !== 3) return false;

  const [hours, minutes, rest] = splitTime;

  const [seconds, milliseconds] = rest?.split(",");

  const timeItems = [hours, minutes, seconds].every((item) => {
    return Number(item) <= 60 && item.length === 2 && Number(item) >= 0 && item;
  });

  const millisecondsIsValid =
    milliseconds?.length === 3 && Number(milliseconds) >= 0 && !!milliseconds;

  return timeItems && millisecondsIsValid;
};
