import { cva } from 'class-variance-authority';

export const typographyVariants = cva('', {
  variants: {
    variant: {
      p: [
        'text-3xl',
        'font-medium',
        'leading-9',
        'tracking-normal',
        'text-neutral-700',
      ],
      span: [
        'text-sm',
        'font-normal',
        'leading-5',
        'tracking-wide',
        'text-neutral-600',
      ],
      h1: [
        'text-3xl',
        'font-semibold',
        'leading-9',
        'tracking-normal',
        'text-neutral-700',
      ],
      h2: [
        'text-2xl',
        'font-semibold',
        'leading-8',
        'tracking-normal',
        'text-neutral-700',
      ],
      h3: [
        'text-xl',
        'font-semibold',
        'leading-7',
        'tracking-normal',
        'text-neutral-700',
      ],
      h4: [
        'text-lg',
        'font-semibold',
        'leading-6',
        'tracking-normal',
        'text-neutral-700',
      ],
      h5: [
        'text-base',
        'font-medium',
        'leading-6',
        'tracking-wider',
        'text-neutral-700',
      ],
      h6: [
        'text-sm',
        'font-medium',
        'leading-5',
        'tracking-wider',
        'text-neutral-700',
      ],
      label: [
        'text-sm',
        'font-normal',
        'leading-5',
        'tracking-normal',
        'text-neutral-600',
      ],
      a: [
        'text-sm',
        'font-medium',
        'leading-5',
        'tracking-wide',
        'text-blue-300',
        'hover:underline',
      ],
    },
  },
});