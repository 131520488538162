import { Link } from "react-router-dom";
import { Typography } from "../../component";
import logo from '../../assets/images/toscribe.png';
import classNames from "classnames";
import { useAuth } from "../../core";
import { ROUTE_PATHS, USER_ROLES } from "../../constants";

export const TradesKonnectLogo = ({ className, imageClassName }) => {
  const auth = useAuth();

  let linkTo = '/';

  if (auth) {
    const {role} = auth.user
    if (role === USER_ROLES.freelancer) {
      linkTo = ROUTE_PATHS.JOBS.LIST
    }
    if (role === USER_ROLES.user) {
      linkTo = ROUTE_PATHS.TRANSCRIPTIONS.LIST
    }
  };

  return (
    <Link to={`${linkTo}`}>
      <div className={"font-semibold text-xl flex items-center text-blue-600"}>
      <img src={logo} alt="toScribe" className={classNames("h-[50px]", imageClassName)}/>
        <Typography
        translationKey="TO_SCRIBE"
        className={classNames('md:hidden text-center ml-3', className)}
        variant='h3'
        />
      </div>
    </Link>
  );
};
