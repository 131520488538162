import { useNavigate, useParams } from "react-router-dom";
import { useGetJob, useSubmitJob } from "../../../common";
import { Loader } from "react-feather";
import { Typography, Player } from "../../../component";
import {
  ACTIONS,
  JOBS_STATUS,
  MODAL_VIEWS,
  ROUTE_PATHS,
} from "../../../constants";
import { toastUtil } from "../../../utils";
import Swal from "sweetalert2";
import { useState } from "react";
import { TranscriptionFeedback } from "../../../component/transcription-feedback";
import { Modal } from "../../../component/modal/modal.component";

export const ViewJob = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalView, setModalView] = useState();
  const [modalIsOpen, setOpenModal] = useState(false);

  const { data: job, isLoading, isError, error } = useGetJob(id);
  const { mutate } = useSubmitJob(id);

  const show = {
    loader: isLoading,
    error: isError,
    errorMessage: error?.response?.data?.message,
    job: !isLoading && !isError && !!job,
  };

  const submitJob = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Are you sure?",
      text: `By submitting, you confirm the correctness of this job`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I confirm!",
    });
    if (isConfirmed) {
      submitForReview();
    }
  };

  const submitForReview = () => {
    mutate(id, {
      onSuccess: () => {
        toastUtil({
          message: "Submitted job successfully",
          theme: "success",
          position: "top-center",
        });
        navigate(ROUTE_PATHS.JOBS.MY_JOBS);
      },
      onError: (error) => {
        toastUtil({
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : "Could not submit job",
          theme: "failure",
          position: "top-center",
        });
      },
    });
  };

  const toggleModal = () => {
    setOpenModal(!modalIsOpen);
  };

  const onConversationsClick = () => {
    setModalView(MODAL_VIEWS.CONVERSATIONS);
    toggleModal();
  };

  return (
    <>
      {show.loader && (
        <div className="my-4 flex flex-col items-center">
          <Loader size={30} />
        </div>
      )}
      {show.error && (
        <div className="my-4 flex flex-col items-center">
          <Typography
            className="break-words"
            variant="h1"
            translationKey="VIEW_TRANSCRIPTION.ERROR_MESSAGE"
            values={{ errorMessage: show.errorMessage }}
          />
        </div>
      )}
      {job && job.url && (
        <Player
          videoUrl={job.url}
          name={job.name}
          videoDuration={job.duration}
          disabled={job.status !== JOBS_STATUS.ASSIGNED}
          actions={[ACTIONS.DELETE]}
          onConversationsClick={onConversationsClick}
          transcriptionList={job.transcriptions}
          conversations={job?.conversations}
          submitForReview={submitJob}
          jobId={id}
          isFreelancer={true}
        />
      )}
      <Modal closeModal={toggleModal} isOpen={modalIsOpen}>
        <TranscriptionFeedback
          toggleModal={toggleModal}
          view={modalView}
          conversations={job?.conversations}
        />
      </Modal>
    </>
  );
};
