import { Statuses, Typography } from "../../../component";
import classNames from "classnames";
import { formatMinutes } from "../../../utils";

export const JobListContent = ({
  jobs,
  isAvailableJobs,
  acceptJob,
  navigateToViewJob,
}) => {
  const columns = ["ID", "SOURCE_LANG", "TARGET_LANG", "DURATION", "PRICE"];

  if (isAvailableJobs) {
    columns.push("ACTIONS");
  }

  if (!isAvailableJobs) {
    columns.push("STATUS");
  }

  return (
    <div className="rounded-lg">
      <div className="overflow-x-auto no-scrollbar">
        <table className="min-w-full bg-white rounded-lg">
          <thead className="bg-gray-200">
            <tr>
              {columns.map((column, index) => {
                return (
                  <th
                    key={column}
                    className={classNames("text-left w-[200px] pl-2")}
                  >
                    <div
                      key={column}
                      className={classNames(
                        "whitespace-nowrap border-r border-r-white py-2 px-2",
                        { "border-r-0": index === columns.length - 1 }
                      )}
                    >
                      <Typography
                        variant="h5"
                        translationKey={`JOBS_TABLE.HEADERS.${column}`}
                      />
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {jobs.map(
              ({
                contentType,
                duration,
                name,
                status,
                amount,
                sourceLanguages,
                targetLanguages,
                _id,
              }) => {
                return (
                  <tr
                    onClick={() => {
                      if (!isAvailableJobs && status === "ASSIGNED") {
                        navigateToViewJob(_id);
                      }
                    }}
                    key={_id}
                    className="border hover:bg-gray-100 cursor-pointer rounded-t-lg"
                  >
                    <td className="p-2 w-[200px]">
                      <Typography
                        className="break-words"
                        variant="h5"
                        translationKey="JOBS_TABLE.VALUES.ID"
                        values={{ jobId: _id }}
                      />
                    </td>
                    <td className="p-2 w-[200px]">
                      <Typography
                        className="break-words"
                        variant="h5"
                        translationKey="JOBS_TABLE.VALUES.SOURCE_LANG"
                        values={{ sourceLangs: sourceLanguages.join(", ") }}
                      />
                    </td>
                    <td className="p-2 w-[200px]">
                      <Typography
                        className="break-words"
                        variant="h5"
                        translationKey="JOBS_TABLE.VALUES.TARGET_LANG"
                        values={{ targetLangs: targetLanguages.join(", ") }}
                      />
                    </td>
                    <td className="p-2 w-[200px]">
                      <Typography
                        className="break-words"
                        variant="h5"
                        translationKey="JOBS_TABLE.VALUES.DURATION"
                        values={{
                          duration: formatMinutes(Math.trunc(duration)),
                        }}
                      />
                    </td>
                    <td className="p-2 w-[200px]">
                      <Typography
                        className="break-words"
                        variant="h5"
                        translationKey="JOBS_TABLE.VALUES.PRICE"
                        values={{ price: amount }}
                      />
                    </td>
                    {isAvailableJobs && (
                      <td className="p-2 w-[200px]">
                        <button
                          className="px-[15px] py-[10px] rounded-md bg-green-100"
                          onClick={() => acceptJob(duration, _id)}
                        >
                          Accept
                        </button>
                      </td>
                    )}
                    {!isAvailableJobs && (
                      <td className="p-2 w-[200px]">
                        <Statuses status={status} />
                      </td>
                    )}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
