import classNames from "classnames";
import { Typography } from "../typography";

export const Statuses = ({ status = 'AWAITING_TRANSLATION' }) => {

  let style

  switch (status) {
    case 'COMPLETED': style = 'bg-green-100'
    break; 
    case 'IN_PROGRESS': style = 'bg-blue-100'
    break; 
    case 'IN_REVIEW': style = 'bg-yellow-100'
    break; 
    case 'ASSIGNED': style = 'bg-blue-100'
    break; 
    case 'CANCELLED': style = 'bg-red-100'
    break; 
    default: style = 'bg-orange-100'
      break;
  }

  return <div className={classNames(style, 'rounded-lg py-1 w-[200px] text-center')}>
    <Typography
      translationKey={`STATUSES.${status}`}
      variant='label'
    />
  </div>
}