import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Lock, Mail } from "react-feather";
import { InputField } from "../../component/form";
import { useLoginSchema } from "./schema";
import { Button, Typography, AnimationWrapper } from "../../component";

import { useLogin } from "../../common/query";
import { toastUtil } from "../../utils";
import { Loader } from "../../component/loader";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS, USER_ROLES } from "../../constants";
import { useAuth } from "../../core";
import { GoogleLogin } from "./google-login.component";
import { jwtDecode } from "jwt-decode";

export const Login = () => {
  const { schema } = useLoginSchema();
  const navigate = useNavigate();
  const { mutate, isLoading } = useLogin();
  const auth = useAuth();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    mutate(values, {
      onError: (err) =>
        toastUtil({ message: err.response?.data?.message, theme: "failure" }),
      onSuccess: (response) => {
        auth?.handleSetToken(response.data);
        jwtDecode(response.data)?.role === USER_ROLES.freelancer
          ? navigate(ROUTE_PATHS.JOBS.LIST)
          : navigate(ROUTE_PATHS.TRANSCRIPTIONS.LIST);
      },
    });
  };
  return (
    <AnimationWrapper>
      <div className="h-full bg-gray-100 py-6">
        <div className="md:w-4/12 w-11/12 mt-8 mb-5 py-4 rounded-lg border border-neutral-100 shadow bg-white h-auto mx-auto">
          <form
            className="relative h-full p-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <InputField
              register={{ ...register("email") }}
              label="AUTH_FORM.FIELD.EMAIL"
              className="py-4"
              inputClassName="bg-gray-50"
              error={errors?.email?.message}
              startIcon={<Mail className="text-gray-300" />}
              required
            />
            <InputField
              register={{ ...register("password") }}
              label="AUTH_FORM.FIELD.PASSWORD"
              className="py-4"
              inputClassName="bg-gray-50"
              error={errors?.password?.message}
              startIcon={<Lock className="text-gray-300" />}
              type="password"
              required
            />

            {isLoading && (
              <div className="flex justify-center">
                <Loader size="30" />
              </div>
            )}

            <Button
              translationKey="AUTH_FORM.ACTIONS.LOGIN"
              className="w-full mt-4 mb-4"
              variant="blue"
              size="lg"
              type="submit"
              disabled={isLoading}
            />
          </form>

          <hr />

          <GoogleLogin />
          <div className="flex md:flex-row flex-col flex-wrap items-center justify-center md:justify-between mt-8 md:mt-0 px-4 py-4">
            <div className="flex items-center justify-center flex-nowrap">
              <Typography
                className="mr-2 whitespace-nowrap text-sm"
                translationKey="AUTH_FORM.NO_ACCOUNT"
                variant="label"
              />
              <Button
                translationKey="AUTH_FORM.ACTIONS.CREATE_ONE"
                variant="link"
                className="whitespace-nowrap text-sm"
                onClick={() => navigate("/" + ROUTE_PATHS.REGISTER)}
              />
            </div>

            <Button
              translationKey="AUTH_FORM.ACTIONS.FORGOT_PASSWORD"
              variant="link"
              onClick={() => navigate("/" + ROUTE_PATHS.FORGOT_PASSWORD)}
              className="text-black text-sm underline whitespace-nowrap mt-3 md:mt-0"
            />
          </div>
        </div>
      </div>
    </AnimationWrapper>
  );
};
