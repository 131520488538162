import { useState } from "react";
import "./index.css";

const MAX_CHAR_PER_LINE = 42;
const MAX_LINES = 2;

export function TranscriptionInput({
  onTextChange,
  text,
  updateFieldOnBlur,
  onFocus,
  disabled
}) {
  const [subtitle, setSubtitle] = useState(text);

  const validateText = (str) => {
    const lines = str.split("\n");

    if (lines.length > MAX_LINES) {
      return [false, "Maximum of 2 lines allowed only."];
    }

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].length > MAX_CHAR_PER_LINE) {
        return [false, "Maximimum of 42 characters per line allowed only."];
      }
    }

    return [true];
  };

  const onChange = (event) => {
    const text = event.target.value;
    const [isValid, message] = validateText(text);
    onTextChange(text);
    if (!isValid) {
      console.log(message);
    }
    setSubtitle(text);
  };

  return (
    <div className="transcription-input-container">
      <div className="transcription-input-cpl">
        <div className="transcription-input-cpl-val">CPL</div>
        <div className="transcription-input-cpl-body">
          <div className="transcription-input-cpl-val">
            {subtitle?.split("\n")[0].length}
          </div>
          <div className="transcription-input-cpl-val">
            {subtitle?.split("\n")[1]?.length}
          </div>
        </div>
      </div>
      <textarea
        className="transcription-input"
        onChange={onChange}
        value={subtitle}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={updateFieldOnBlur}
        placeholder="Subtitle..."
      />
    </div>
  );
}
