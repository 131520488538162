import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button } from "../button";
import { InputField } from "../form";
import { useRejectionSchema } from "./schema";
import { useRejectTranscription } from "../../common";
import { formatDateToTimeAgo, toastUtil } from "../../utils";
import { useParams } from "react-router-dom";
import { Loader } from "react-feather";
import { MODAL_VIEWS } from "../../constants";
import { Typography } from "../typography";

export const TranscriptionFeedback = ({
  toggleModal,
  updateTranscription,
  conversations,
  view,
}) => {
  const { schema } = useRejectionSchema();
  const { id } = useParams();

  const { mutate, isLoading } = useRejectTranscription();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    mutate(
      { payload: values, id },
      {
        onSuccess: () => {
          toggleModal();
          reset();
          toastUtil({
            message: "Successfully approved transcription",
            theme: "success",
            position: "top-center",
          });
          updateTranscription(values);
        },
        onError: (err) => {
          toastUtil({
            message:
              err.response?.data?.message || "Unable to fetch transcription",
            theme: "failure",
          });
        },
      }
    );
  };

  return (
    <>
      {isLoading && (
        <div className="my-4 flex flex-col items-center">
          <Loader size={30} />
        </div>
      )}

      <div className="md:w-[40em] min-h-[15em] max-h-[25em] overflow-y-scroll">
        {view === MODAL_VIEWS.ADD_FEEBACK && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              register={{ ...register("text") }}
              label="VIEW_TRANSCRIPTION.REJECT_TRANSCRIPTION.REASON"
              type="textarea"
              error={errors?.text?.message}
            />
            <div className="w-full flex justify-end">
              <Button
                translationKey="MODAL.SUBMIT"
                variant={"blue"}
                type={"submit"}
                size={"xl"}
                className={
                  "gap-[0] mt-[1em] px-[1em] w-[8em]  text-white outline-none"
                }
              />
            </div>
          </form>
        )}
        {view === MODAL_VIEWS.CONVERSATIONS &&
          conversations?.map((conversation) => (
            <div
              key={conversation?.createdAt}
              className="flex flex-col w-[22em] md:w-full py-[1em] border-b"
            >
              <Typography
                className="break-words text-grey-100"
                variant="span"
                translationKey="VIEW_TRANSCRIPTION.CONVERSATIONS.CONVERSATION"
                values={{ conversation: conversation?.text }}
              />
              <div className="flex justify-end">
                <Typography
                  className="break-words text-grey-200 text-xs"
                  variant="span"
                  translationKey="VIEW_TRANSCRIPTION.CONVERSATIONS.CONVERSATION"
                  values={{
                    conversation: formatDateToTimeAgo(conversation?.createdAt),
                  }}
                />
              </div>
            </div>
          ))}
      </div>
    </>
  );
};
