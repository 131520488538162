import { useMutation } from "react-query";
import { apiRoutes, axiosInstance } from "../../api";

export const usePayWithPromocode = () => {
  return useMutation(
    async ({reference, promocode, }) => await axiosInstance.get(`${apiRoutes.transactions}/${reference}/pay?code=${promocode}`)
  );
};

export const useFetchTransaction = () => {
  return useMutation(
    async (id) => await axiosInstance.get(`${apiRoutes.transactions}/${id}`)
  );
};

export const useRetryTransaction = () => {
  return useMutation(
    async (id) => await axiosInstance.get(`${apiRoutes.transactions}/${id}/retry`)
  );
};
