import { Link, useNavigate } from "react-router-dom";
import {
  LogIn,
  PlusCircle,
  Menu,
} from "react-feather";
import classNames from "classnames";

import { ROUTE_LABELS, ROUTE_PATHS } from "../../constants";
import { MenuLinks } from "./menu-link.component";
import { MobileSlide } from "./mobile-slider.component";
import { TradesKonnectLogo } from "./trades-konnect.component";
import { Button } from "../../component";

const menuLinks = [
  { to: ROUTE_PATHS.HOME, label: ROUTE_LABELS.HOME }
];

export const NoAuthHeader = ({ setShowSideMenu, location, showSideMenu}) => {
  const navigate = useNavigate();

  return (
  
    <div className="h-[100px] drop-shadow py-4 bg-white px-2 lg:px-0 sticky top-0 z-50">
      <nav className="container mx-auto h-full flex items-center md:px-8 px-4">
        <div className="flex items-center flex-1">
          <TradesKonnectLogo />
          <div className={"hidden lg:flex h-full"}>
            <ul className="items-center flex justify-between w-full h-full lg:mx-2 px-2">
              <MenuLinks setShowSideMenu={setShowSideMenu} menuLinks={menuLinks} />
            </ul>
          </div>
        </div>
        <div className="hidden lg:flex items-center">
          <ul className="items-center flex justify-between h-full ">
            <li className="mx-2 px-2 text-center flex items-center">
              <Link to={`/${ROUTE_PATHS.LOGIN}`}>{ROUTE_LABELS.LOGIN}</Link>
              <LogIn size={14} className="ml-1" />
            </li>
          </ul>
          <div className="hover:text-secondary transition duration-300 flex items-center rounded-full bg-white p-3">
            <Button
              variant="ghost"
              translationKey="REGISTER.LABEL"
              onClick={() => navigate(`/${ROUTE_PATHS.REGISTER}`)}
              className={classNames("border-0 text-black active:text-black active:bg-transparent hover:bg-transparent hover:text-red-700", {
                "text-red-700":
                  location?.pathname === `/${ROUTE_PATHS.REGISTER}`,
              })}
            />
            <PlusCircle
              size={14}
              className={classNames("ml-1", {
                "text-red-700":
                  location?.pathname === `/${ROUTE_PATHS.REGISTER}`,
              })}
            />
          </div>
        </div>
        <button
          onClick={() => setShowSideMenu(!showSideMenu)}
          className="outline-none lg:hidden"
        >
          <Menu size={30} className="pr-1" color="black" />
        </button>
      </nav>
      <MobileSlide setShowSideMenu={setShowSideMenu} 
      menuLinks={[...menuLinks, { to: ROUTE_PATHS.LOGIN, label: ROUTE_LABELS.LOGIN }]}
      showSideMenu={showSideMenu}/>
    </div>
  );
};
