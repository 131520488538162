import "../PlayerRender.css";
import { CurrentTimestamp } from "../CurrentTimeStamp";
import { TimeFrame } from "../TimeFrame";
import { TranscriptionInput } from "../TranscriptionInput";
import { convertSecondsToTime } from "../utils/timeConverter";
import { OptionsButton } from "../OptionsButton";

export function Transcription({
  onTextChange,
  transcription,
  duration,
  errors,
  isLast,
  currentRef,
  onNewTimestamp,
  onCurrentTimestampClick,
  updateProgressOnFocus,
  updateFieldOnBlur,
  actions,
  disabled,
  handleAction,
  index,
}) {
  return (
    <>
      <div
        ref={currentRef}
        key={transcription.startTime}
        className="transcription-container md:br-0.5em br 0"
      >
        <div className="mr-[0.5em]">{index}</div>
        <TimeFrame
          startTime={convertSecondsToTime(Number(transcription.startTime))}
          onCurrentTimestampClick={onCurrentTimestampClick}
          errors={errors}
          disabled={disabled}
          endTime={
            transcription.endTime
              ? convertSecondsToTime(Number(transcription.endTime)).toString()
              : ""
          }
          duration={duration}
          onBlur={(e, key) => updateFieldOnBlur(e, key)}
          onFocus={(e, key) => updateProgressOnFocus(e, key)}
        />
        <TranscriptionInput
          onTextChange={(value) => onTextChange(value)}
          disabled={disabled}
          onFocus={(e) =>
            updateProgressOnFocus(
              convertSecondsToTime(Number(transcription.startTime)),
              "startTime"
            )
          }
          updateFieldOnBlur={(e) => updateFieldOnBlur(e.target.value, "text")}
          text={transcription.text}
        />
        {!disabled && (
          <div className="flex flex-start">
            <OptionsButton
              options={actions}
              handleAction={(option) => handleAction(option)}
            />
          </div>
        )}
      </div>
      <div className="new-transcription">
        {!disabled && (
          <CurrentTimestamp
            text={isLast ? "Add subtitle" : "Use previous end time"}
            onClick={onNewTimestamp}
          />
        )}
      </div>
    </>
  );
}
