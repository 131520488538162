export const ROUTE_LABELS = {
  HOME: "Home",
  ABOUT: "About",
  LOGIN: "Login",
  REGISTER: "Register",
  TRANSCRIPTIONS: {
    LIST: "Transcriptions",
  },
  JOBS: {
    LIST: "Jobs",
  },
};
export const ROUTE_PATHS = {
  DASHBOARD: "dashboard",
  TRANSCRIPTIONS: {
    LIST: "/transcriptions",
    CREATE: "/transcriptions/create",
  },
  JOBS: {
    LIST: "/jobs",
    VIEW: "/jobs/:id",
    MY_JOBS: "/my-jobs",
  },
  HOME: "",
  TRANSACTIONS: "/transactions",
  ABOUT: "about",
  REGISTER: "register",
  LOGIN: "login",
};

export const USER_ROLES = {
  user: "user",
  freelancer: "freelancer",
  admin: "admin",
};

export const TRANSCRIPTION_STATUS = {
  COMPLETED: "COMPLETED",
  IN_REVIEW: "IN_REVIEW",
  AWAITING_PAYMENT: "AWAITING_PAYMENT",
};
export const JOBS_STATUS = {
  COMPLETED: "COMPLETED",
  IN_REVIEW: "IN_REVIEW",
  ASSIGNED: "ASSIGNED",
};

export const ACTIONS = {
  DELETE: "Delete",
  MARK_FOR_REVIEW: "Mark for review",
};

export const MODAL_VIEWS = {
  CONVERSATIONS: "CONVERSATIONS",
  ADD_FEEBACK: "ADD_FEEBACK",
};

export const MAX_FILE_SIZE = 10485760;

export const PAGINATION_ITEMS_PER_PAGE = 10;
