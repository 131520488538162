import { useIntl } from "react-intl";
import * as yup from "yup";

export const useFormSchema = () => {
  const intl = useIntl();

  const schema = yup.object({
    items: yup
      .array()
      .of(
        yup.object({
          language: yup
            .object()
            .required(
              intl.formatMessage({ id: "AUTH_FORM.FORM_ERRORS.REQUIRED" })
            ),
          targetLanguages: yup
            .array()
            .of(
              yup
                .object()
                .required(
                  intl.formatMessage({ id: "AUTH_FORM.FORM_ERRORS.REQUIRED" })
                )
            )
            .min(
              1,
              intl.formatMessage({ id: "AUTH_FORM.FORM_ERRORS.REQUIRED" })
            ),
          file: yup
            .mixed()
            .required(
              intl.formatMessage({ id: "AUTH_FORM.FORM_ERRORS.REQUIRED" })
            ),
        })
      )
      .min(1, intl.formatMessage({ id: "AUTH_FORM.FORM_ERRORS.REQUIRED" })),
  });

  return { schema };
};

export const usePromocodeSchema = () => {
  const intl = useIntl();
  const schema = yup.object({
    promocode: yup
      .string()
      .required(intl.formatMessage({ id: "AUTH_FORM.FORM_ERRORS.REQUIRED" })),
  });

  return { schema };
};
