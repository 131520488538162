import { useIntl } from "react-intl";
import * as yup from "yup";
import "yup-phone-lite";


export const useRegisterSchema = () => {

  const intl = useIntl()
   const schema = yup.object({
    firstName: yup.string().required(
      intl.formatMessage({ id: 'AUTH_FORM.FORM_ERRORS.REQUIRED'})
    ),
    lastName: yup.string().required(
      intl.formatMessage({ id: 'AUTH_FORM.FORM_ERRORS.REQUIRED'})
    ),
    email: yup.string().email().required(
      intl.formatMessage({ id: 'AUTH_FORM.FORM_ERRORS.REQUIRED'})
    ),
    password: yup.string().required(
      intl.formatMessage({ id: 'AUTH_FORM.FORM_ERRORS.REQUIRED'})
    ),
    confirmPassword: yup.string()
    .required(intl.formatMessage({ id: 'AUTH_FORM.FORM_ERRORS.REQUIRED'}))
    .oneOf([yup.ref('password'), null], intl.formatMessage({ id: 'AUTH_FORM.FORM_ERRORS.PASSWORD_MATCH'}))
  }).required();


  return { schema }
}