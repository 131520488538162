import { RouterProvider } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { router } from "./appRoutes";
import ErrorBoundary from "./ErrorBoundary";

import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./core";
import { DEFAULT_LOCALES, I18n } from "./i18n";
import { useState } from "react";

export const App = () => {
  const [gotToHome, setGotoHome] = useState();
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
          if (error && (error.status === 401 || error.status === 404)) {
            return false;
          }
          return failureCount < 2;
        },
        onError: (error) => {
          if (error?.response?.status === 401) {
            setGotoHome(true);
          }
        },
      },
    },
  });

  return (
    <QueryClientProvider client={client}>
      <ErrorBoundary gotToHome={gotToHome}>
        <I18n language={"en"} locales={DEFAULT_LOCALES}>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </I18n>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};
