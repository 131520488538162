import { useNavigate, useLocation, Link } from "react-router-dom";
import { ChevronRight } from "react-feather";
import classNames from "classnames";
import { ROUTE_PATHS } from "../../constants";
import { useAuth } from "../../core";
import { Button } from "../../component";

export const MenuLinks = ({
  setShowSideMenu,
  menuLinks,
  menuItemClassName,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const auth = useAuth();

  const handleOnClick = (href) => {
    setShowSideMenu(false);
    navigate(href);
  };
  return (
    <>
      {menuLinks.map((menu, id) => {
        const pathname = location?.pathname ?? "";
        const icon = menu?.icon;

        return (
          <li
            key={id}
            onClick={() => handleOnClick(menu.to)}
            className={classNames(
              "lg:text-center flex items-center justify-between border border-[gray] cursor-pointer border-t-[0.3px] border-r-0 border-l-0 border-b-[0.3px] lg:border-t-0 lg:border-none lg:h-auto h-[40px] leading-[40px]",
              { "text-blue-200": pathname === `/${menu.to}` }
            )}
          >
            <Link
              to={menu.to}
              className={classNames(
                "lg:mr-0 hover:text-secondary transition duration-300",
                menuItemClassName
              )}
            >
              {menu.label}
            </Link>
            {auth?.isAuthenticated ? (
              <div className="mr-4 md:mr-0">{icon}</div>
            ) : (
              <ChevronRight size={14} className="mr-2 lg:hidden" />
            )}
          </li>
        );
      })}
      {!auth?.isAuthenticated && (
        <div className="lg:hidden hover:text-secondary transition duration-300 flex items-center rounded-full bg-white p-3">
          <Button
            translationKey="REGISTER.LABEL"
            onClick={() => handleOnClick(`/${ROUTE_PATHS.ADD_LISTING}`)}
            size="md"
            variant="blue"
            className="w-full mt-6 bg-blue-600"
          />
        </div>
      )}
    </>
  );
};
