import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {  Lock, Mail } from "react-feather";
import { InputField } from "../../component/form"
import { Button, Typography, AnimationWrapper } from "../../component";

import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants";
import { useRegisterSchema } from "./schema";
import { useRegister } from "../../common";
import { toastUtil } from "../../utils";
import { useAuth } from "../../core";

export const Register = () => {
  const { schema } = useRegisterSchema()
  const auth = useAuth()
  const navigate = useNavigate();
  const { mutate, isLoading } = useRegister()
  const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmit = (values) => { 
    mutate(values, {
      onError: (err => toastUtil({message: err.response?.data?.message, theme: 'failure'})),
      onSuccess: (response) => {
        auth?.handleSetToken(response.data)
        navigate(ROUTE_PATHS.DASHBOARD)
      }
    })
    
  }
  return <AnimationWrapper>

      <div className="h-full bg-gray-100 py-6">
      <div className="md:w-4/12 w-11/12 mt-8 mb-5 py-4 rounded-lg border border-neutral-100 shadow bg-white h-auto mx-auto">
        <form className="relative h-full p-4" onSubmit={handleSubmit(onSubmit)}>

          <InputField
          register={{...register('firstName')}}
          label="AUTH_FORM.FIELD.FIRSTNAME"
          className="py-4"
          inputClassName='bg-gray-50'
          error={errors?.email?.message}
          startIcon={<Mail className="text-gray-300" />}
          required
        />
          <InputField
          register={{...register('lastName')}}
          label="AUTH_FORM.FIELD.LASTNAME"
          className="py-4"
          inputClassName='bg-gray-50'
          error={errors?.email?.message}
          startIcon={<Mail className="text-gray-300" />}
          required
        />
          <InputField
          register={{...register('email')}}
          label="AUTH_FORM.FIELD.EMAIL"
          className="py-4"
          inputClassName='bg-gray-50'
          error={errors?.email?.message}
          startIcon={<Mail className="text-gray-300" />}
          required
        />
          <InputField
          register={{...register('password')}}
          label="AUTH_FORM.FIELD.PASSWORD"
          className="py-4"
          inputClassName='bg-gray-50'
          error={errors?.password?.message}
          startIcon={<Lock className="text-gray-300" />}
          type="password"
          required
        />
          <InputField
          register={{...register('confirmPassword')}}
          label="AUTH_FORM.FIELD.COMFIRM_PASS"
          className="py-4"
          inputClassName='bg-gray-50'
          error={errors?.password?.message}
          startIcon={<Lock className="text-gray-300" />}
          type="password"
          required
        />

        <Button
          translationKey="AUTH_FORM.ACTIONS.CREATE_ACCOUNT"
          className='w-full mt-4 mb-4'
          variant='blue'
          size='lg'
          type='submit'
          isLoading={isLoading}
          disabled={isLoading}
        />
      </form>
      <div className="flex md:flex-row flex-col flex-wrap items-center justify-center md:justify-between mt-8 md:mt-0 px-4 py-4">
        <div className="flex items-center justify-center flex-nowrap">
          <Typography
            className="mr-2 whitespace-nowrap text-sm"
            translationKey="AUTH_FORM.EXISTING_ACCOUNT"
            variant="label"
          />
          <Button
            translationKey="AUTH_FORM.ACTIONS.LOGIN"
            variant="link"
            className="whitespace-nowrap text-sm"
            onClick={() => navigate("/" + ROUTE_PATHS.LOGIN)}
          />
        </div>
      </div>
      </div>
    </div>
  </AnimationWrapper>
}