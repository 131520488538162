import { formatMoney } from "../../utils";
import { Button } from "../button";
import { Typography } from "../typography";

export const Price = ({ isLoading, isValid, durations }) => {
  return (
    <div className="flex-col md:flex  border border-gray md:w-[20em] w-full px-[1em] py-[1em] md:fixed md:top-[9em] mt-[1em] md:mt-0 md:right-13 rounded-xl border-solid">
      <div className="flex">
        <Typography
          className="break-words"
          variant="h3"
          translationKey="CREATE_TRANSCRIPTION.ESTIMATED_TOTAL"
        />
        <Typography
          className="break-words ml-[0.5em]"
          variant="h3"
          translationKey="CREATE_TRANSCRIPTION.ESTIMATED_TOTAL_VALUE"
          values={{ total: formatMoney(Math.ceil(durations * 250)) }}
        />
      </div>
      <Button
        disabled={!isValid || isLoading}
        type="submit"
        size="lg"
        className="w-full md:w-[100%] mt-4"
        variant="blue"
        translationKey="CREATE_TRANSCRIPTION.FORM.CREATE"
      />
    </div>
  );
};
