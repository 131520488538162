import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { jwtDecode } from "jwt-decode";
import { TOKEN_NAME } from "../core.constant";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSetToken = useCallback((value) => {
    localStorage.setItem(TOKEN_NAME, value);
    setIsAuthenticated(true);
  }, []);

  const handleRemoveToken = useCallback(() => {
    localStorage.removeItem(TOKEN_NAME);
    setIsAuthenticated(false);
  }, []);

  if (localStorage.getItem(TOKEN_NAME) && !isAuthenticated) {
    setIsAuthenticated(true)
  }

  const value = useMemo(
    () => ({
      isAuthenticated,
      user: isAuthenticated ? jwtDecode(localStorage.getItem(TOKEN_NAME)) : {},
      handleRemoveToken,
      handleSetToken,
    }),
    [handleSetToken, isAuthenticated, handleRemoveToken]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const AuthConsumer = AuthContext.Consumer;

export const useAuth = () => useContext(AuthContext);
