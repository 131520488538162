import { useNavigate } from "react-router-dom"
import { Button, Typography } from "../../../component"
import { ROUTE_PATHS } from "../../../constants"

export const NoJobs = () => {
  const navigate = useNavigate()

  return (
    <div className="h-[500px] flex justify-center items-center">
      <div className="justify-center flex flex-col items-center gap-4">
        <Typography
          translationKey="No jobs yet"
          variant="h3"
        />
        <Typography
          translationKey="Files you have submitted for transcription will appear here"
          variant="md"
        />
        <Button
          translationKey="Get started"
          variant="primary"
          size="lg"
          onClick={() => navigate(ROUTE_PATHS.jobs.CREATE)}
          className="text-lg"
        />
      </div>
    </div>
  )
}