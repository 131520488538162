import { NoJobs } from "./no-job.component";
import { useGetJobs } from "../../../common/query";
import { JobListContent } from "./jobs-list-content.component";
import { Loader } from "../../../component";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../constants";

export const MyJobs = () => {
  const { data, isLoading, isError } = useGetJobs(true);
  const navigate = useNavigate();

  const jobs = data?.jobs;

  const show = {
    noJobs: !isLoading && !isError && jobs?.length === 0,
    loader: isLoading,
    error: isError,
    jobs: !isLoading && !isError && !!jobs?.length,
  };

  return (
    <>
      {show.loader && (
        <div className="my-4 flex flex-col items-center">
          <Loader size={30} />
        </div>
      )}
      {show.noJobs && <NoJobs />}
      {show.jobs && !show.loader && (
        <JobListContent
          jobs={jobs}
          navigateToViewJob={(id) => navigate(`${ROUTE_PATHS.JOBS.LIST}/${id}`)}
        />
      )}
    </>
  );
};
