
import { Blocks } from  'react-loader-spinner'

export const Loader = ({ size = "80", className}) => {
  return (
    <div className={className}>
      <Blocks
        height={size}
        width={size}
        color="gray"
        ariaLabel="triangle-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible
      />
    </div>
  )
}