import axios from "axios";
import { apiRoutes } from "../api";
import { TOKEN_NAME } from "../core/core.constant";
import { toastUtil } from "./toast";

export const isFileTypeSupported = (file) => {
  if (file) {
    const ext = file?.type?.split("/")[1];
    return ["png", "jpeg", "jpg"].includes(ext);
  }

  return false;
};

const audioFilesTypes = ["auido/mp3", "audio/ogg", "audio/mpeg"];
const videoFilesTypes = ["video/*"];

export const acceptedFilesTypes = [...audioFilesTypes, videoFilesTypes];

export const openInputFile = async (onChange) => {
  const input = document.createElement("input");

  input.setAttribute("type", "file");
  input.setAttribute("multiple", true);
  input.setAttribute("accept", acceptedFilesTypes.join());
  input.click();

  input.onchange = async (event) => {
    const files = event.target.files;
    const erroredFiles = [];
    const filesWithDuration = [];

    const processFile = (file) => {
      return new Promise((resolve, reject) => {
        const element = document.createElement(
          audioFilesTypes.includes(file.type) ? "audio" : "video"
        );
        if (element) {
          const fileURL = URL.createObjectURL(file);
          element.src = fileURL;
          element.addEventListener("loadedmetadata", () => {
            const duration = element.duration;
            file.duration = duration;
            URL.revokeObjectURL(fileURL);
            resolve(file);
          });
          element.addEventListener("error", () => {
            URL.revokeObjectURL(fileURL);
            reject(file.name);
          });
        } else {
          reject(file.name);
        }
      });
    };

    try {
      const results = await Promise.all(Array.from(files).map(processFile));
      filesWithDuration.push(...results);
    } catch (erroredFileName) {
      erroredFiles.push(erroredFileName);
    }

    if (erroredFiles.length) {
      toastUtil({
        message: "Some of the files you selected are unsupported",
        theme: "failure",
      });
    } else {
      onChange(filesWithDuration);
    }
  };
};

export const mapFiles = async ({ file, targetLanguages, language }) => {
  const chunkSize = 9 * 1024 * 1024; // 1MB chunk size (adjust as needed)
  const totalChunks = Math.ceil(file?.size / chunkSize);
  const resourceId = Date.now();

  // Iterate over file chunks
  for (let i = 0; i < totalChunks; i++) {
    const start = i * chunkSize;
    const end = Math.min((i + 1) * chunkSize, file.size);
    const chunk = file.slice(start, end);

    try {
      const res = await axios.get(apiRoutes.uploadUrl(resourceId, i + 1), {
        headers: {
          Authorization: localStorage.getItem(TOKEN_NAME),
        },
      });

      await axios.put(res.data.data.url, chunk, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });
    } catch (error) {
      console.error(`Error uploading chunk ${i + 1}:`, error);
      throw error;
    }
  }

  return {
    resourceId: resourceId.toString(),
    totalChunks,
    duration: file.duration,
    sourceLanguages: language,
    targetLanguages: targetLanguages,
    fileName: file.name,
  };
};

export const sortData = (records = [], sortField, direction) => {
  let dir = direction;
  dir = dir === "asc" ? "desc" : "asc";

  const newData = records.sort((a, b) => {
    if (a[sortField] < b[sortField] && dir === "asc") {
      return -1;
    }

    if (a[sortField] > b[sortField] && dir === "desc") {
      return -1;
    } else {
      return 1;
    }
  });

  return { sortedData: newData, dir };
};

export const formatMoney = (price) =>
  new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(price);

export function formatDateToTimeAgo(date) {
  const now = new Date();
  const diffInSeconds = Math.floor((now - new Date(date)) / 1000);

  const units = [
    { name: "year", seconds: 31536000 },
    { name: "month", seconds: 2592000 },
    { name: "day", seconds: 86400 },
    { name: "hour", seconds: 3600 },
    { name: "minute", seconds: 60 },
    { name: "second", seconds: 1 },
  ];

  for (let unit of units) {
    const interval = Math.floor(diffInSeconds / unit.seconds);
    if (interval >= 1) {
      return interval === 1
        ? `${interval} ${unit.name} ago`
        : `${interval} ${unit.name}s ago`;
    }
  }

  return "just now";
}
