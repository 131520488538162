import { AnimatePresence, motion } from "framer-motion"

export const AnimationWrapper = ({ children }) => (
    <AnimatePresence>
    	<motion.div 
				initial={{ opacity: 0.1 }}
				animate={{ opacity: 1 }}
				className="h-full"
				transition={{ duration: 1, stiffness: 'smooth' }}
				exit={{ opacity: 0 }}
      	>   
				{children}
    	</motion.div>
    </AnimatePresence>
)