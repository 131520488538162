import { useMutation, useQuery } from "react-query";
import { apiRoutes, axiosInstance } from "../../api";

export const useGetTranscriptions = () => {
  return useQuery({
    queryFn: async () => {
      const {
        data: { data: transcriptions },
      } = await axiosInstance.get(apiRoutes.transcriptions);
      return transcriptions;
    },
  });
};

export const useGetTranscription = () => {
  return useMutation(
    async (id) => await axiosInstance.get(`${apiRoutes.transcriptions}/${id}`)
  );
};

export const useApproveTranscription = () => {
  return useMutation(
    async (id) =>
      await axiosInstance.get(`${apiRoutes.transcriptions}/${id}/approve`)
  );
};

export const useRejectTranscription = () => {
  return useMutation(
    async ({ payload, id }) =>
      await axiosInstance.post(
        `${apiRoutes.transcriptions}/${id}/reject`,
        payload
      )
  );
};

export const useDownloadTranscription = () => {
  return useMutation(
    async (id) =>
      await axiosInstance.get(`${apiRoutes.transcriptions}/${id}/export`)
  );
};
