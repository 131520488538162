import { NoTranscriptions } from "./no-transcriptions.component";
import {
  useGetTranscriptions,
  useRetryTransaction,
} from "../../../common/query";
import { TranscriptionListContent } from "./transcription-list-content.component";
import { Loader } from "../../../component";
import { useNavigate } from "react-router-dom";
import { toastUtil } from "../../../utils";
import { ROUTE_PATHS } from "../../../constants";

export const TranscriptionList = () => {
  const { data, isLoading, isError } = useGetTranscriptions();
  const navigate = useNavigate();

  const { mutate, isLoading: retryingTransaction } = useRetryTransaction();

  const transcriptions = data?.transcriptions;
  const count = data?.count;

  const onRetryTransaction = (transcriptionId) => {
    mutate(transcriptionId, {
      onSuccess: ({ data: { data } }) => {
        navigate(`${ROUTE_PATHS.TRANSACTIONS}/${data.transactionRef}`);
      },
      onError: () =>
        toastUtil({
          message:
            "Unable to retry transaction for this transacription at this time",
          theme: "failure",
        }),
    });
  };

  const show = {
    noTranscriptions: !isLoading && !isError && transcriptions?.length === 0,
    loader: isLoading || retryingTransaction,
    error: isError,
    transcriptions: !isLoading && !isError && !!transcriptions.length,
  };

  return (
    <>
      {show.loader && (
        <div className="my-4 flex flex-col items-center">
          <Loader size={30} />
        </div>
      )}
      {show.noTranscriptions && <NoTranscriptions />}
      {show.transcriptions && (
        <TranscriptionListContent
          onRetryTransaction={onRetryTransaction}
          transcriptions={transcriptions}
          count={count}
        />
      )}
    </>
  );
};
