import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useEffect } from "react";

import { ROUTE_PATHS, USER_ROLES } from "./constants";
import {
  Login,
  Home,
  Register,
  TranscriptionList,
  CreateTranscription,
  PreviewPrice,
} from "./pages";
import { I18n, DEFAULT_LOCALES } from "./i18n";
import { useAuth } from "./core";
import {
  AnimationWrapper,
  AuthWrapper,
  Error404Page,
  Loader,
} from "./component";
import { Header } from "./common";
import { ViewTranscription } from "./pages/transcriptions/view";
import { Jobs, ViewJob } from "./pages/jobs";
import { jwtDecode } from "jwt-decode";

const Layout = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") ?? localStorage.getItem("session");

  useEffect(() => {
    if (!auth?.isLoading) {
      if (auth?.isAuthenticated || (auth?.isAuthenticated && !token)) {
        return navigate(window.location);
      }

      if (!auth?.isAuthenticated && token) {
        auth?.handleSetToken(token);
        const role = jwtDecode(token)?.role;
        if (role === USER_ROLES.user) {
          return navigate(ROUTE_PATHS.TRANSCRIPTIONS.LIST);
        }

        if (role === USER_ROLES.freelancer) {
          return navigate(ROUTE_PATHS.JOBS.LIST);
        }
      }

      if (!auth?.isAuthenticated && !token) {
        return navigate(ROUTE_PATHS.HOME);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isAuthenticated, navigate]);

  if (auth?.isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <I18n language={"en"} locales={DEFAULT_LOCALES}>
      <div className="w-full flex flex-col md:h-screen">
        <Header />
        <div className="flex-1">
          <Outlet />
        </div>
      </div>
    </I18n>
  );
};

const RoleWrapper = ({ requiredRoles = [], children }) => {
  const auth = useAuth();

  if (!requiredRoles.includes(auth.user.role)) {
    return (
      <Error404Page
        buttonText="ERROR_PAGE.HOME"
        redirectTo={ROUTE_PATHS.HOME}
      />
    );
  }

  return children;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path={`/${ROUTE_PATHS.HOME}`} element={<Home />} />
      <Route path={`/${ROUTE_PATHS.LOGIN}`} element={<Login />} />
      <Route path={`/${ROUTE_PATHS.REGISTER}`} element={<Register />} />
      <Route element={<AuthWrapper />}>
        <Route
          path={`/${ROUTE_PATHS.TRANSCRIPTIONS.LIST}`}
          element={
            <RoleWrapper requiredRoles={[USER_ROLES.user]}>
              <TranscriptionList />
            </RoleWrapper>
          }
        />
        <Route
          path={`/${ROUTE_PATHS.TRANSCRIPTIONS.CREATE}`}
          element={
            <RoleWrapper requiredRoles={[USER_ROLES.user]}>
              <CreateTranscription />
            </RoleWrapper>
          }
        />
        <Route
          path={`/${ROUTE_PATHS.TRANSCRIPTIONS.LIST}/:id`}
          element={
            <RoleWrapper requiredRoles={[USER_ROLES.user]}>
              <ViewTranscription />
            </RoleWrapper>
          }
        />
        <Route
          path={`/${ROUTE_PATHS.TRANSACTIONS}/:id`}
          element={
            <RoleWrapper requiredRoles={[USER_ROLES.user]}>
              <PreviewPrice />
            </RoleWrapper>
          }
        />
        <Route
          path={`/${ROUTE_PATHS.JOBS.LIST}`}
          element={
            <RoleWrapper requiredRoles={[USER_ROLES.freelancer]}>
              <Jobs />
            </RoleWrapper>
          }
        />
        <Route
          path={`/${ROUTE_PATHS.JOBS.MY_JOBS}`}
          element={
            <RoleWrapper requiredRoles={[USER_ROLES.freelancer]}>
              <Jobs />
            </RoleWrapper>
          }
        />
        <Route
          path={`/${ROUTE_PATHS.JOBS.VIEW}`}
          element={
            <RoleWrapper requiredRoles={[USER_ROLES.freelancer]}>
              <ViewJob />
            </RoleWrapper>
          }
        />
      </Route>
      <Route
        path="*"
        element={
          <AnimationWrapper>
            <Error404Page
              buttonText="ERROR_PAGE.HOME"
              redirectTo={ROUTE_PATHS.HOME}
            />
          </AnimationWrapper>
        }
      />
    </Route>
  )
);
