import { useEffect, useState } from "react";
import { validateTimeFormat } from "../utils/timeConverter";

export function TimeFrameInput({ time, onBlur, onFocus, isInvalid, disabled }) {
  const [text, setText] = useState(time);
  const [hasError, setHasError] = useState(isInvalid);

  useEffect(() => {
    setText(time);
    setHasError(false);
  }, [isInvalid, time]);

  const onChange = (event) => {
    const value = event.target.value;
    setHasError(false);
    if (!validateTimeFormat(value)) {
      setHasError(true);
    }
    setText(value);
  };

  return (
    <input
      className={`time-frame-input px-[20px] ${
        hasError ? "time-frame-input__invalid" : ""
      }`}
      onChange={onChange}
      placeholder="00:00:00,000"
      disabled={disabled}
      onBlur={(e) => onBlur && onBlur(e.target.value)}
      onFocus={(e) => onFocus && onFocus(e.target.value)}
      value={text}
    />
  );
}
