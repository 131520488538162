import { Link } from 'react-router-dom'
import ErrorMessageImage from '../../assets/images/404-image.png'
import { Button } from '../button'
import { Typography } from '../typography'
import { ROUTE_PATHS } from '../../constants'

export const Error404Page = ({ redirectTo= ROUTE_PATHS.LISTINGS, onButtonClick = null, message = 'ERROR_PAGE.ERROR_MESSAGE', buttonText = 'ERROR_PAGE.BUTTON_TEXT'}) => {

  return <div className="h-full flex flex-col justify-center items-center">
    <img src={ErrorMessageImage} alt='Error page' className='mt-12 md:mt-0 md:h-[300px] px-6 mb-6'/>
      <Typography
        translationKey={message}
        variant='p'
        className='text-center px-4 text-lg max-w-[700px]'
      />
     {onButtonClick ?
        <Button
          translationKey={buttonText}
          className="rounded-lg px-4 py-2 mt-4 mb-12"
          onClick={onButtonClick}
          variant='destructivePrimary'
        />
    :
    <Link to={`/${redirectTo}`}>
      <Button
        translationKey={buttonText}
        className="rounded-lg px-4 py-2 mt-4 mb-12"
        variant='destructivePrimary'
      /> 
    </Link>
    }
  </div>
}