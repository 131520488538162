import toast from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css';
import 'react-simple-toasts/dist/theme/success.css';
import 'react-simple-toasts/dist/theme/failure.css';


export const toastUtil = ({ message, position = 'top-center', theme = 'success', duration = 5000 }) => {
  
  return(
    toast(message, { position, theme, duration })
  )
}