export const TOKEN_NAME = 'session'

export const SUPPORTED_SOURCE_LANGUAGES = [
    { value: 'english', label: "English" },
    { value: 'igbo', label: "Igbo" },
    { value: 'hausa', label: "Hausa" },
    { value: 'yoruba', label: "Yoruba" },
];

export const SUPPORTED_TARGET_LANGUAGES = SUPPORTED_SOURCE_LANGUAGES.slice(0, 1);
