import classNames from "classnames";

import { Typography } from "../../typography";
import { Transcription } from "../Transcription";

export const Transcriptions = ({
  showNewSubtitleButton,
  addTranscription,
  disabled,
  transcriptions,
  itemRefs,
  playerRef,
  updateProgressOnFocus,
  onCurrentTimestampClick,
  currentSubtitleIndex,
  duration,
  timeFrameErrors,
  updateFieldOnBlur,
  onTextChange,
  transcriptionsContainerRef,
  handleAction,
  actions,
  isFreelancer,
}) => (
  <div
    className={classNames(
      {
        "h-[28%]": !showNewSubtitleButton,
        "h-[30%]": showNewSubtitleButton,
      },
      "transcriptions-container md:relative md:h-[80vh] fixed bottom-0 left-0 right-0"
    )}
    ref={transcriptionsContainerRef}
  >

    {!isFreelancer && !transcriptions.length && (
      <div className="flex items-center justify-center h-[500px]">
        <Typography
          translationKey="VIEW_TRANSCRIPTION.SUBTITLES.AWAITING_SUBTITLE"
          variant="h2"
          className="text-md md:text-lg"
        />
      </div>
    )}


    {transcriptions?.map((transcription, index) => (
      <Transcription
        currentRef={(el) => (itemRefs.current[index] = el)}
        onCurrentTimestampClick={() =>
          onCurrentTimestampClick(index, playerRef.current.currentTime())
        }
        onNewTimestamp={() =>
          addTranscription(
            index,
            index === transcriptions.length - 1 ? "current" : "prev"
          )
        }
        index={index+1}
        isFreelancer={isFreelancer}
        isCurrent={currentSubtitleIndex === index}
        key={transcription.startTime}
        transcription={transcription}
        actions={actions}
        duration={duration}
        disabled={disabled}
        handleAction={(action) => handleAction(action, index)}
        updateProgressOnFocus={(e, key) => updateProgressOnFocus(e, key, index)}
        isLast={index === transcriptions.length - 1}
        errors={timeFrameErrors}
        updateFieldOnBlur={(val, key) => updateFieldOnBlur(val, key, index)}
        onTextChange={(val) => onTextChange(val, "text", index)}
      />
    ))}
  </div>
);
