import { useEffect } from "react";
import { UploadForm } from "./form.component";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toastUtil } from "../../../utils";
import { ROUTE_PATHS } from "../../../constants";

export const CreateTranscription = () => {
  const [searchParams] = useSearchParams();
  const txRef = searchParams.get("trxref");
  const navigate = useNavigate();

  useEffect(() => {
    if (txRef) {
      toastUtil({ message: "Transcription created", duration: 6000 });
      setTimeout(() => navigate(ROUTE_PATHS.TRANSCRIPTIONS.LIST));
    }
  }, [txRef, navigate]);

  const handleRedirection = ({transactionRef}) => {
    navigate(`${ROUTE_PATHS.TRANSACTIONS}/${transactionRef}`)
  };

  return (
    <div>
      <UploadForm
        onSuccess={(response) => handleRedirection(response)}
      />
    </div>
  );
};
