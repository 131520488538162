import { useIntl } from "react-intl";
import * as yup from "yup";
import "yup-phone-lite";


export const useLoginSchema = () => {

  const intl = useIntl()
   const schema = yup.object({
    email: yup.string().email().required(
      intl.formatMessage({ id: 'AUTH_FORM.FORM_ERRORS.REQUIRED'})
    ),
    password: yup.string().required(
      intl.formatMessage({ id: 'AUTH_FORM.FORM_ERRORS.REQUIRED'})
    ),
  }).required();


  return { schema }
}