export function convertSecondsToTime(seconds) {
  // Separate seconds into whole seconds and milliseconds
  let wholeSeconds = Math.floor(seconds);

  // Convert whole seconds into hours, minutes, and seconds
  let hours = Math.floor(wholeSeconds / 3600);
  let remainingSeconds = wholeSeconds % 3600;
  let minutes = Math.floor(remainingSeconds / 60);
  remainingSeconds = remainingSeconds % 60;

  // Format milliseconds

  // Format time components into desired format
  let formattedTime =
    String(hours).padStart(2, "0") +
    ":" +
    String(minutes).padStart(2, "0") +
    ":" +
    String(remainingSeconds).padStart(2, "0")

  return formattedTime;
}


export const formatMinutes = (minutes) => {
  if (typeof minutes !== 'number' || minutes < 0) {
    return "Invalid input";
  }

  minutes = Math.ceil(minutes);
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let result = "";

  if (hours > 0) {
    result += `${hours} hour${hours !== 1 ? 's' : ''}`;
  }

  if (hours > 0 && remainingMinutes > 0) {
    result += " ";
  }

  if (remainingMinutes > 0) {
    result += `${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}`;
  }

  return result || "0 minutes";
}