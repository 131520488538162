import "./index.css";
import { TimeFrameInput } from "./TimeFrameInput";
import { CurrentTimestamp } from "../CurrentTimeStamp";

export function TimeFrame({
  startTime,
  endTime,
  errors,
  disabled,
  onBlur,
  onCurrentTimestampClick,
  onFocus,
}) {
  return (
    <div className="timeframe-container">
      <div className="timeframe-right">
        <TimeFrameInput
          disabled={disabled}
          isInvalid={errors.includes("startTime")}
          onBlur={(time) => onBlur(time, "startTime")}
          onFocus={(time) => onFocus(time, "startTime")}
          time={startTime}
        />
        <TimeFrameInput
          isInvalid={errors.includes("endTime")}
          onBlur={(time) => onBlur(time, "endTime")}
          onFocus={(time) => onFocus(time, "endTime")}
          time={endTime}
          disabled={disabled}
        />
        {!endTime ? <CurrentTimestamp onClick={onCurrentTimestampClick} /> : ""}
      </div>
    </div>
  );
}
