import { useMutation } from "react-query";
import { apiRoutes, axiosInstance } from "../../api";


export const useAddSubtitle = () => {
  return useMutation(async ({ id, payload }) => {
    return await axiosInstance.post(
      `${apiRoutes.subtitles}/jobs/${id}`,
      payload
    );
  });
};

export const useDeleteSubtitle = () => {
  return useMutation(async ({ jobId, id }) => {
    return await axiosInstance.delete(
      `${apiRoutes.subtitles}/${id}/jobs/${jobId}`
    );
  });
};

export const useUpdateSubtitle = () => {
  return useMutation(async ({ jobId, id, payload }) => {
    return await axiosInstance.patch(
      `${apiRoutes.subtitles}/${id}/jobs/${jobId}`,
      payload
    );
  });
};
