import classNames from "classnames"
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl"
import Select from 'react-select';

export const SelectField = ({
  className,
  name,
  selectClassName,
  label,
  orientation = 'vertical',
  labelClassName,
  isMultiple=false,
  error,
  disabled,
  required,
  options,
  placeholder = null,
  existingOptions,
  control
}) => {

  const { formatMessage } = useIntl()
  const labelTranslation = label && formatMessage({id: label })
  const placeholderTranslation = placeholder && formatMessage({id: placeholder })

  const style = {
    control: base => ({
      ...base,
      border: 0,
      minHeight: '48px',
      backgroundColor: 'transparent',
      boxShadow: "none"
    })
  };

return(
      <div className={classNames('flex flex-row', className, {'flex-col': orientation === 'vertical'})}>
        <div>
          {required && <span className="text-red-300 text-md mr-1">*</span>}
          <label className={labelClassName} htmlFor={labelTranslation}>{labelTranslation}</label>
        </div>
        
        <div className="flex flex-col mb-3">
          <div>
            <Controller
              name={name}
              control={control}
              defaultValue={existingOptions}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isDisabled={disabled}
                    options={options}
                    placeholder={placeholderTranslation}
                    isMulti={isMultiple}
                    styles={style}
                    className={classNames('mt-2 px-2 border rounded-md outline-none focus:border-2', selectClassName)}
                  />
                )
              }}
            />
          </div>
          { error && <span className="text-red-300 mt-1">{error}</span>}
        </div>
      </div>
    )
}