import { useIntl } from "react-intl";
import * as yup from "yup";

export const useRejectionSchema = () => {
  const intl = useIntl();
  const schema = yup.object({
    text: yup
      .string()
      .required(intl.formatMessage({ id: "AUTH_FORM.FORM_ERRORS.REQUIRED" })),
  });

  return { schema };
};
