import { useLocation } from "react-router-dom";

import { useState } from "react";
import { AuthedHeader } from "./auth-header.component";
import { useAuth } from "../../core";
import { NoAuthHeader } from "./no-auth-header.component";

export const Header = () => {
  const auth = useAuth();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const location = useLocation();

  // console.log(auth?.isAuthenticated, '@@@@@@@@@@======')
  return auth?.isAuthenticated ? (
    <AuthedHeader
      showSideMenu={showSideMenu}
      setShowSideMenu={setShowSideMenu}
    />
  ) : (
    <NoAuthHeader
      showSideMenu={showSideMenu}
      location={location}
      setShowSideMenu={setShowSideMenu}
    />
  );
};
