import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

// import { Typography } from "../typography";
import { usePromocodeSchema } from "./form-schema";
import { useFetchTransaction, usePayWithPromocode } from "../../../common";
import { Button, InputField, Typography } from "../../../component";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatMoney, toastUtil } from "../../../utils";
import { ROUTE_PATHS } from "../../../constants";
import { useEffect } from "react";
import { Loader } from "react-feather";

export const PreviewPrice = () => {
  const { schema } = usePromocodeSchema();
  const { id } = useParams();
  const navigate = useNavigate();
  const { mutate, isLoading } = usePayWithPromocode();
  const {
    mutate: fetchTransaction,
    data: transaction,
    error,
    isError,
    isLoading: fetchingTransaction,
  } = useFetchTransaction();

  const amount = transaction?.data?.data?.price;
  const authorizationUrl = transaction?.data?.data?.authorization_url;

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    mutate(
      { ...values, reference: id },
      {
        onError: (err) =>
          toastUtil({
            message:
              err.response?.data?.message || "Could not complete request",
            theme: "failure",
          }),
        onSuccess: () => {
          navigate(ROUTE_PATHS.TRANSCRIPTIONS.LIST);
        },
      }
    );
  };

  useEffect(() => {
    fetchTransaction(id);
  }, [id, fetchTransaction]);

  useEffect(() => {
    if (isError) {
      toastUtil({
        message: error.response?.data?.message || "Could not fetch transaction",
        theme: "failure",
      });
    }
  }, [isError, error]);

  const payWithPaystack = () => {
    window.location.replace(authorizationUrl);
  };

  const show = {
    noTransaction: !fetchingTransaction && !isError,
    loader: fetchingTransaction,
    error: isError,
    transaction: !fetchingTransaction && !isError && !!transaction,
  };

  return (
    <>
      {show.loader && (
        <div className="my-4 flex flex-col items-center">
          <Loader size={30} />
        </div>
      )}
      <div className="flex justify-center align-center">
        <div className="border border-gray w-[33em] rounded-xl border-solid px-[2em] pt-[0]">
          <div>
            <div className="flex pt-[2em] justify-end">
              <Typography
                className="break-words ml-[0.5em]"
                variant="h2"
                translationKey="CREATE_TRANSCRIPTION.ESTIMATED_TOTAL_VALUE"
                values={{ total: formatMoney(Math.ceil(amount)) }}
              />
            </div>
            <form className="relative h-full" onSubmit={handleSubmit(onSubmit)}>
              <InputField
                register={{ ...register("promocode") }}
                label="TRANSACTIONS.PREVIEW_PRICE.PROMOCODE"
                className="py-1"
                labelClassName="text-white text-xs"
                inputClassName="bg-gray-50"
                error={errors?.promocode?.message}
              />
              <Button
                translationKey="TRANSACTIONS.PREVIEW_PRICE.PAY_WITH_PROMOCODE"
                className="w-full mt-4 mb-4"
                variant="blue"
                size="lg"
                type="submit"
                isLoading={isLoading}
                disabled={isLoading}
              />
            </form>
            <Button
              onClick={payWithPaystack}
              translationKey="TRANSACTIONS.PREVIEW_PRICE.CONTINUE_TO_PAY"
              className="w-full mt-4 mb-4"
              variant="link"
              size="lg"
              isLoading={isLoading}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};
