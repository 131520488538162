import { cva } from 'class-variance-authority';

/**
 * Class Ordering
 * - base state
 * - hover state
 * - focus state
 * - active state
 * - disabled state
 */

export const buttonVariants = cva(
  [
    'flex items-center justify-center gap-2', // scaffolding
    'font-medium', // font
    'transition', // transitions
    'rounded border', // borders
    'focus:outline-none focus-visible:ring focus-visible:ring-offset-1', // focus state
    'disabled:cursor-not-allowed disabled:opacity-40', // disabled state
  ],
  {
    variants: {
      variant: {
        primary: [
          'border-transparent bg-red-500 text-white',
          'hover:bg-red-600',
          'focus:ring focus:ring-neutral-600',
          'active:bg-red-600',
          'disabled:bg-red-100',
        ],
        secondary: [
          'border-neutral-600 bg-white text-neutral-600',
          'hover:border-neutral-400 hover:bg-neutral-100',
          'focus:border-neutral-700',
          'active:border-neutral-400 active:bg-neutral-300',
          'disabled:border-neutral-200 disabled:bg-white disabled:text-neutral-300',
        ],
        // tw - Workaround to trigger tailwind intellisense
        link: [
          'border-transparent text-blue-300',
          'hover:text-blue-400 hover:underline hover:underline-offset-4',
          'focus:ring focus:ring-neutral-700',
        ],
        ghost: [
          'border-transparent bg-transparent text-blue-300',
          'hover:bg-neutral-100 hover:text-blue-300',
          'focus:border-neutral-700 focus:bg-transparent',
          'active:bg-neutral-200 active:text-blue-300',
          'disabled:bg-transparent disabled:text-neutral-300',
        ],
        subduedGhost: [
          'bg-neutral-100 text-neutral-600',
          'hover:bg-neutral-200 hover:text-neutral-600',
          'focus:border-neutral-700 focus:bg-neutral-100',
          'active:bg-neutral-300 active:text-neutral-600',
          'disabled:bg-neutral-100 disabled:text-neutral-300',
        ],
        // tw - Workaround to trigger tailwind intellisense
        destructivePrimary: [
          'border-transparent bg-red-300 text-white',
          'hover:bg-red-400',
          'focus:border-neutral-700 focus:bg-red-300',
          'active:bg-red-500',
          'disabled:bg-red-100',
        ],
        destructiveGhost: [
          'border-transparent bg-transparent text-red-300',
          'hover:bg-neutral-100 hover:text-red-300',
          'focus:border-neutral-700 focus:bg-transparent focus:text-red-300',
          'active:bg-neutral-200 active:text-red-300',
          'disabled:bg-transparent disabled:text-red-200',
        ],
        blue: [
          "flex p-[0.4em] px-[.8em] border-blue-500 text-white bg-blue-500 gap-[0] rounded-md text-sm md:text-md mr-[1em] md:mr-[0]",
          'text-white'
        ],
      },
      // tw - Workaround to trigger tailwind intellisense
      size: {
        sm: 'px-3 py-1 text-xs tracking-wider',
        md: 'px-3 py-2 text-sm tracking-wide',
        lg: 'p-3 text-sm tracking-normal',
      },
      isCondensed: {
        true: 'p-0',
      },
      withDefaultSizing: {
        true: 'min-w-[96px] max-w-[400px] max-[375px]:min-w-[12px]',
      },
    },
    compoundVariants: [
      { variant: 'link', size: 'sm', className: 'leading-4' },
      { variant: 'link', size: 'md', className: 'leading-5' },
      { variant: 'link', size: 'lg', className: 'text-base leading-6' },
    ],
  }
);