import { Eye, Mic, Plus, Video } from "react-feather";
import { Button, Statuses, Typography } from "../../../component";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS, TRANSCRIPTION_STATUS } from "../../../constants";
import { convertSecondsToTime } from "../../../utils";

export const TranscriptionListContent = ({
  transcriptions,
  onRetryTransaction,
  count
}) => {
  const columns = [
    "NAME",
    "SOURCE_LANG",
    "TARGET_LANG",
    "DURATION",
    "PRICE",
    "STATUS",
    "ACTIONS",
  ];
  const navigate = useNavigate();

  return (
    <div className="rounded-lg h-[100vh]">
      <div className="flex justify-between mb-2 md:mb-6  items-center">
        <Typography
          translationKey="TRANSCRIPTIONS_TABLE.HEADER"
          variant="h2"
          className="text-md md:text-lg"
        />
        <div className="flex items-center">
          <Button
            onClick={() => navigate(ROUTE_PATHS.TRANSCRIPTIONS.CREATE)}
            variant="blue"
            size="lg"
            startIcon={<Plus size={16} />}
            translationKey="TRANSCRIPTIONS_TABLE.CREATE"
          />
        </div>
      </div>
      <div className="overflow-x-auto no-scrollbar">
        <table className="min-w-full bg-white rounded-lg">
          <thead className="bg-gray-200">
            <tr>
              {columns.map((column, index) => {
                const isActionButton = column === "ACTIONS";
                return (
                  <th
                    key={column}
                    className={classNames(
                      { "w-[40px]": isActionButton },
                      "text-left w-[200px] pl-2"
                    )}
                  >
                    <div
                      key={column}
                      className={classNames(
                        "whitespace-nowrap border-r border-r-white py-2 px-2",
                        { "border-r-0": index === columns.length - 1 }
                      )}
                    >
                      {isActionButton ? null : (
                        <Typography
                          variant="h5"
                          translationKey={`TRANSCRIPTIONS_TABLE.HEADERS.${column}`}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {transcriptions.map(
              ({
                contentType,
                duration,
                name,
                status,
                price = 100,
                sourceLanguages,
                targetLanguages,
                _id,
              }) => {
                const Icon = contentType === "audio" ? Mic : Video;
                return (
                  <tr
                    onClick={() => {
                      if (status === TRANSCRIPTION_STATUS.AWAITING_PAYMENT) {
                        return onRetryTransaction(_id);
                      }
                      navigate(
                        `${ROUTE_PATHS.TRANSCRIPTIONS.LIST}/${_id}`
                      );
                    }}
                    key={_id}
                    className="border hover:bg-gray-100 cursor-pointer rounded-t-lg"
                  >
                    <td className="py-2 px-4 flex items-center">
                      <div className="flex max-w-[250px] items-center">
                        <Icon size={18} className="mr-2" />
                        <Typography
                          className="truncate"
                          variant="h5"
                          translationKey="TRANSCRIPTIONS_TABLE.VALUES.NAME"
                          values={{ name }}
                        />
                      </div>
                    </td>
                    <td className="p-2 w-[200px]">
                      <Typography
                        className="break-words"
                        variant="h5"
                        translationKey="TRANSCRIPTIONS_TABLE.VALUES.SOURCE_LANG"
                        values={{ sourceLangs: sourceLanguages.join() }}
                      />
                    </td>
                    <td className="p-2 w-[200px]">
                      <Typography
                        className="break-words"
                        variant="h5"
                        translationKey="TRANSCRIPTIONS_TABLE.VALUES.TARGET_LANG"
                        values={{ targetLangs: targetLanguages.join() }}
                      />
                    </td>
                    <td className="p-2 w-[200px]">
                      <Typography
                        className="break-words"
                        variant="h5"
                        translationKey="TRANSCRIPTIONS_TABLE.VALUES.DURATION"
                        values={{ duration: convertSecondsToTime(duration) }}
                      />
                    </td>
                    <td className="p-2 w-[200px]">
                      <Typography
                        className="break-words"
                        variant="h5"
                        translationKey="TRANSCRIPTIONS_TABLE.VALUES.PRICE"
                        values={{ price }}
                      />
                    </td>
                    <td className="p-2 w-[200px]">
                      <Statuses status={status} />
                    </td>
                    <td className="py-2 flex justify-center items-center">
                      <Eye className="text-blue-300" size={16} />
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
