import { PlusAndMinusIcon } from "../assets/PlusAndMinusIcon";
import "./index.css";

export function CurrentTimestamp({ onClick, text }) {
  return (
    <div className="current-timestamp-container" onClick={onClick}>
      <div className="current-timestamp-btn">{text || "Current Timestamp"}</div>
      <PlusAndMinusIcon size="l" />
    </div>
  );
}
