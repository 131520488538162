import "./PlayerRender.css";
import React, { useEffect, useRef, useState } from "react";

import VideoJS from "./Player";
import {
  convertSecondsToTime,
  convertTimeToSeconds,
  validateTimeFormat,
} from "./utils/timeConverter";
import { Typography } from "../typography";
import { PlayerControl } from "./player-controls";
import { Transcriptions } from "./Transcriptions";
import { ACTIONS, JOBS_STATUS, TRANSCRIPTION_STATUS } from "../../constants";
import {
  useAddSubtitle,
  useDeleteSubtitle,
  useUpdateSubtitle,
} from "../../common";
import { TranscriptionFeedbackButton } from "../transcription-feedback-button";
import { Button } from "../button";
import classNames from "classnames";

const MAX_DIFF = 5;

export const Player = ({
  videoUrl,
  name,
  jobId,
  transcriptionList = [],
  videoDuration,
  disabled = false,
  actions,
  isFreelancer = false,
  submitForReview,
  onApprove,
  status,
  jobStatus,
  onDownload,
  onReject,
  conversations,
  onConversationsClick
}) => {
  const duration = videoDuration;
  const playerRef = React.useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSubtitleIndex, setCurrentSubtitleIndex] = useState();
  const itemRefs = React.useRef([]);
  const [timeFrameErrors, setTimeFrameErrors] = useState([]);
  const transcriptionsContainerRef = useRef(null);
  const [useDefaultInterval, setUseDefaultInterval] = useState(false);
  const targetTime = 2;
  const [transcriptions, setTranscriptions] = useState(transcriptionList);
  const isPaused = playerRef?.current?.paused();

  const { mutate } = useAddSubtitle();
  const { mutate: mutateDeleteSubtitle } = useDeleteSubtitle();
  const { mutate: mutateUpdateSubtitle } = useUpdateSubtitle();

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: videoUrl,
      },
    ],
    controlBar: {
      children: ["progressControl"],
    },
  };

  useEffect(() => {
    if (
      currentSubtitleIndex !== undefined &&
      transcriptionsContainerRef.current
    ) {
      const container = transcriptionsContainerRef.current;
      const item = itemRefs.current[currentSubtitleIndex];

      if (item) {
        const containerTop = container.getBoundingClientRect().top;
        const itemTop = item.getBoundingClientRect().top;

        container.scrollBy({
          top: itemTop - containerTop,
          behavior: "smooth",
        });
      }
    }
  }, [currentSubtitleIndex]);

  useEffect(() => {
    setIsPlaying(isPaused || isPaused === undefined ? false : true);
  }, [isPaused]);

  useEffect(() => {
    if (useDefaultInterval) {
      const [currentTimeInteger, currentTimeSub] = currentTime
        .toFixed(3)
        .split(".");
      const [targetTimeInteger, targetTimeSub] = targetTime
        .toFixed(3)
        .split(".");

      const subDiff = Number(targetTimeSub) - Number(currentTimeSub);
      if (
        Number(currentTimeInteger) === Number(targetTimeInteger) &&
        MAX_DIFF >= subDiff
      ) {
        playerRef.current?.pause();
        setIsPlaying(false);
      }
    }
  }, [currentTime, targetTime, useDefaultInterval]);

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on("timeupdate", () => {
      const newTime = player.currentTime();
      setCurrentTime(newTime);
    });
  };

  const playVideo = () => {
    if (playerRef.current) {
      if (!isPlaying) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const moveVideo = (direction) => {
    const currentTime = playerRef.current.currentTime();
    if (direction === "left") {
      playerRef.current.currentTime(currentTime - 1);
    }
    if (direction === "right") {
      playerRef.current.currentTime(currentTime + 1);
    }
  };

  const createSubtitle = (id, index, startTime) => {
    mutate({ id, payload: { index, startTime } });
  };

  const addTranscription = (index = 0, type) => {
    if (currentTime === transcriptions[transcriptions.length - 1]?.startTime) {
      return;
    }

    if (type === "prev") {
      const nextIndex = index + 1;
      const currentEndTime = transcriptions[index]?.endTime;
      const nextStartTime = transcriptions[nextIndex]?.startTime;

      if (nextStartTime && Number(currentEndTime) < Number(nextStartTime)) {
        const newIndex = (nextIndex + index) / 2;
        createSubtitle(jobId, newIndex, currentEndTime);
        return setTranscriptions([
          ...transcriptions.slice(0, nextIndex),
          {
            index: newIndex,
            startTime: currentEndTime,
            endTime: nextStartTime,
            text: "",
          },
          ...transcriptions.slice(index + 1, transcriptions.length),
        ]);
      }
    } else {
      const newIndex = transcriptions.length + 1;
      createSubtitle(jobId, newIndex, currentTime);
      setTranscriptions([
        ...transcriptions,
        {
          index: transcriptions.length + 1,
          startTime: currentTime,
          endTime: "",
          text: "",
        },
      ]);
    }
  };

  const onTextChange = (value, field, index, shouldSendUpdate) => {
    const transcription = transcriptions[index];
    setTranscriptions([
      ...transcriptions.slice(0, index),
      { ...transcription, [field]: value },
      ...transcriptions.slice(index + 1, transcriptions.length),
    ]);

    if (shouldSendUpdate) {
      if (field === "text" && value.length === 0) {
        return;
      }
      mutateUpdateSubtitle({
        jobId,
        id: transcription.index,
        payload: { [field]: value },
      });
    }
  };

  const getCurrentSubTitle = () => {
    const indices = [];
    transcriptions.forEach((item, index) => {
      if (
        Number(currentTime.toFixed(3)) >=
          Number(Number(item.startTime).toFixed(3)) &&
        Number(currentTime.toFixed(3)) <=
          Number(Number(item.endTime).toFixed(3))
      ) {
        indices.push(index);
      }
    });
    const index = indices[indices.length - 1];
    if (index > -1) {
      if (currentSubtitleIndex !== index) {
        setCurrentSubtitleIndex(index);
      }
      return transcriptions[index]?.text;
    }
    return "";
  };

  const updateProgress = (time, key, index, shouldSendUpdate = false) => {
    const timeInSeconds = convertTimeToSeconds(time);
    onTextChange(timeInSeconds, key, index, shouldSendUpdate);
    playerRef.current.currentTime(timeInSeconds);
  };

  const updateFieldOnBlur = (val, key, index) => {
    if (key === "text") {
      return onTextChange(val, key, index, true);
    }
    const newTime = Number(convertTimeToSeconds(val));
    if (validateTimeFormat(val) && val && newTime < duration) {
      const previousEndTime = transcriptions[index - 1]?.endTime;
      const nextStartTime = transcriptions[index + 1]?.startTime;
      const startTime = transcriptions[index].startTime;
      const endTime = transcriptions[index].endTime;
      if (key === "startTime") {
        if (previousEndTime) {
          if (Number(previousEndTime) > newTime) {
            setTimeFrameErrors([...timeFrameErrors, key]);
            return;
          }
        }
        if (nextStartTime) {
          if (Number(nextStartTime) < newTime) {
            setTimeFrameErrors([...timeFrameErrors, key]);
            return;
          }
        }
        if (endTime) {
          if (Number(endTime) < newTime) {
            setTimeFrameErrors([...timeFrameErrors, key]);
            return;
          }
        }
      }
      if (key === "endTime") {
        if (startTime) {
          if (Number(startTime) > newTime) {
            setTimeFrameErrors([...timeFrameErrors, key]);
            return;
          }
        }
        if (nextStartTime) {
          if (Number(nextStartTime) < newTime) {
            setTimeFrameErrors([...timeFrameErrors, key]);
            return;
          }
        }
      }

      updateProgress(val, key, index, true);
      setTimeFrameErrors(timeFrameErrors.filter((err) => key !== err));
    } else {
      const alreadyHasError = timeFrameErrors.find((err) => err === key);
      if (alreadyHasError) {
        setTimeFrameErrors(timeFrameErrors.filter((err) => key !== err));
      } else {
        setTimeFrameErrors([...timeFrameErrors, key]);
      }
    }
  };

  const onCurrentTimestampClick = (index, time) => {
    const startTime = transcriptions[index].startTime;
    const nextStartTime = transcriptions[index + 1]?.startTime;
    if (
      Number(startTime) >= time ||
      (nextStartTime && Number(nextStartTime) < time)
    ) {
      return console.log("Invalid endTime");
    }
    updateFieldOnBlur(convertSecondsToTime(time), "endTime", index);
  };

  const updateProgressOnFocus = (e, key, index) => {
    const endTime = transcriptions[index].endTime;
    if (e && endTime) {
      updateProgress(e, key, index);
    }
  };

  const deleteTranscription = (index) => {
    const transcription = transcriptions[index];
    const newTranscriptions = transcriptions.filter((_, i) => index !== i);
    mutateDeleteSubtitle({ id: transcription?.index, jobId });
    setTranscriptions(newTranscriptions);
  };

  const handleAction = (action, index) => {
    switch (action) {
      case ACTIONS.DELETE:
        deleteTranscription(index);
        break;

      default:
        break;
    }
  };

  const showNewSubtitleButton = !disabled && !transcriptions.length;


  return (
    <div className="player-editor-container">
      <div className="xs:w-full md:w-[50%]">
        <div
          className={classNames("hidden md:flex md:w-full", {
            "justify-between": showNewSubtitleButton,
            "justify-end": !showNewSubtitleButton,
          })}
        >
          {showNewSubtitleButton && (
            <div className="transcriptions-header md:0 hidden md:flex">
              <Button
                translationKey="TRANSCRIPTION.NEW_SUBTITLE"
                variant={"blue"}
                className="h-[2.6em]"
                onClick={() => addTranscription(0)}
              />
            </div>
          )}
          <TranscriptionFeedbackButton
            onSubmit={submitForReview}
            onDownload={onDownload}
            onApprove={onApprove}
            onConversationsClick={onConversationsClick}
            onReject={onReject}
            count={conversations?.length}
            isInReview={
              status === TRANSCRIPTION_STATUS.IN_REVIEW &&
              jobStatus === JOBS_STATUS.IN_REVIEW
            }
            isFreelancer={isFreelancer}
            isApproved={status === TRANSCRIPTION_STATUS.COMPLETED}
          />
        </div>
        <Transcriptions
          showNewSubtitleButton={showNewSubtitleButton}
          addTranscription={addTranscription}
          disabled={disabled}
          isFreelancer={isFreelancer}
          transcriptions={transcriptions}
          itemRefs={itemRefs}
          playerRef={playerRef}
          updateProgressOnFocus={updateProgressOnFocus}
          onCurrentTimestampClick={onCurrentTimestampClick}
          currentSubtitleIndex={currentSubtitleIndex}
          duration={duration}
          timeFrameErrors={timeFrameErrors}
          updateFieldOnBlur={updateFieldOnBlur}
          handleAction={handleAction}
          actions={actions}
          onTextChange={onTextChange}
          transcriptionsContainerRef={transcriptionsContainerRef}
        />
      </div>
      <div className="flex flex-col md:w-[45%] md:relative sm:w-full">
        <div className="md:hidden flex justify-end">
          <TranscriptionFeedbackButton
            onConversationsClick={onConversationsClick}
            onSubmit={submitForReview}
            onDownload={onDownload}
            onApprove={onApprove}
            count={conversations?.length}
            onReject={onReject}
            isInReview={
              status === TRANSCRIPTION_STATUS.IN_REVIEW &&
              jobStatus === JOBS_STATUS.IN_REVIEW
            }
            isFreelancer={isFreelancer}
            isApproved={status === TRANSCRIPTION_STATUS.COMPLETED}
          />
        </div>
        <Typography
          className={"mb-2"}
          variant="h5"
          translationKey="VIEW_TRANSCRIPTION.TRANSCRIPTION_NAME"
          values={{ name }}
        />
        <div className="player-container">
          <div className="video-player">
            <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
            {getCurrentSubTitle() ? (
              <div className="subtitle">
                <p className="subtitle-text md">{getCurrentSubTitle()}</p>
              </div>
            ) : (
              ""
            )}
          </div>
          <PlayerControl
            isPlaying={isPlaying}
            moveVideo={moveVideo}
            playVideo={playVideo}
            playerRef={playerRef}
            disabled={disabled}
            setUseDefaultInterval={setUseDefaultInterval}
            useDefaultInterval={useDefaultInterval}
          />
        </div>

        {showNewSubtitleButton && (
          <div className="transcriptions-header mt-[1em] md:hidden">
            <button
              className="new-subtitle border border-blue-500  p-[0.4em] rounded-md bg-blue-500 text-white"
              onClick={() => addTranscription(0)}
            >
              New Subtitle +
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
