export const ThreeDots = () =>
  <svg
    className=""
    width="1em"
    viewBox="0 0 24 24"
    id="three-dots"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="_20x20_three-dots--grey"
      dataName="20x20/three-dots--grey"
      transform="translate(24) rotate(90)"
    >
      <rect id="Rectangle" width="24" height="24" fill="none" />
      <circle
        id="Oval"
        cx="2"
        cy="2"
        r="2"
        transform="translate(5 11)"
        stroke="#000000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <circle
        id="Oval-2"
        dataName="Oval"
        cx="2"
        cy="2"
        r="2"
        transform="translate(11 11)"
        stroke="#000000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <circle
        id="Oval-3"
        dataName="Oval"
        cx="2"
        cy="2"
        r="2"
        transform="translate(17 11)"
        stroke="#000000"
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
    </g>
  </svg>;

