import { Typography } from "../../../component";
import classNames from "classnames";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../constants";
import { JobList } from "./job-list.component";
import { MyJobs } from "./my-jobs.component";

export const Jobs = () => {
  const VIEWS = {
    JOBS: "Jobs",
    MY_JOBS: "My Jobs",
  };
  const { pathname } = useLocation();
  const [view, setView] = useState(
    pathname === ROUTE_PATHS.JOBS.LIST ? VIEWS.JOBS : VIEWS.MY_JOBS
  );

  const navigate = useNavigate();

  const changeView = (view) => {
    setView(view);
    navigate(
      view === VIEWS.JOBS ? ROUTE_PATHS.JOBS.LIST : ROUTE_PATHS.JOBS.MY_JOBS
    );
  };

  return (
    <>
      <nav className="flex mb-2 md:mb-6  items-center border-b-[1px] ">
        {Object.values(VIEWS).map((item) => (
          <div
            key={item}
            onClick={() => changeView(item)}
            className={classNames("px-[10px] py-[15px] cursor-pointer", {
              "border-b-[3px] pt-[18px] border-blue-500": view === item,
            })}
          >
            <Typography
              key={item}
              translationKey="JOBS.HEADER"
              className="text-md md:text-lg"
              values={{ name: item }}
            />
          </div>
        ))}
      </nav>
      {view === VIEWS.JOBS && <JobList />}
      {view === VIEWS.MY_JOBS && <MyJobs />}
    </>
  );
};
