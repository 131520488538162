import { useNavigate } from "react-router-dom";
import { Button, Typography } from "../../component";
import { ROUTE_PATHS } from "../../constants";

export const Home = () => {
  const navigation = useNavigate();
  const languages = [
    "HOME.SAMPLE_LANGUAGES.PIDGIN",
    "HOME.SAMPLE_LANGUAGES.YORUBA",
    "HOME.SAMPLE_LANGUAGES.IGBO",
    "HOME.SAMPLE_LANGUAGES.HAUSA",
    "HOME.SAMPLE_LANGUAGES.TWI",
    "HOME.SAMPLE_LANGUAGES.SWAHILI",
  ];
  const links = ["instagram", "linkedIn", "twitter"];
  const features = [
    {
      title: "Unlimited uploads with no restrictions.",
      body: "Upload files of any size and length without limits. Our software supports all file types.",
    },
    {
      title: "Import from other platforms",
      body: "Import desired public links from other platforms and sync Toscribe into your current workflow.",
    },
    {
      title: "Security & Confidentiality",
      body: "Files are protected and kept private to ensure the confidentiality of your subtitles.",
    },
    {
      title: "Workspaces for collaboration",
      body: "Set up collaborative workspaces to easily share files with your team.",
    },
    {
      title: "Machine Translation",
      body: "Automatically translate transcriptions and subtitles into the most popular languages",
    },
  ];
  return (
    <div className="w-full">
      <div className="flex flex-col items-center bg-blue-500 h-[40em] justify-center">
        <div className="md:w-[38em] flex items-center">
          <Typography
            className={"text-white text-center"}
            translationKey="HOME.MESSAGE"
            variant="h1"
          />
        </div>
        <Typography
          className={"text-center mt-[3em] md:w-[20em] text-white"}
          translationKey="HOME.EXPORT_LANGUAGES"
          variant="h5"
        />

        <Button
          onClick={() => navigation(ROUTE_PATHS.LOGIN)}
          variant={"blue"}
          size={"xl"}
          className="px-[2em] py-[1em]  bg-white-100 mt-[3em] md:flex text-blue-500"
          translationKey="HOME.GET_STARTED"
        />
      </div>
      <div className="w-full h-[15em] mt-[3em] align-center flex flex-col items-center justify-center">
        <Typography
          className={"text-center"}
          translationKey="HOME.MESSAGE_2"
          variant="h2"
        />
        <div className="mt-[1em] md:w-[30em] justify-center flex flex-wrap">
          {languages.map((language) => (
            <Button
              key={language}
              variant={"blue"}
              size={"xl"}
              translationKey={language}
              className="px-[2em] py-[1em] m-[1em] bg-grey-100 border-grey-200 text-grey-500 md:flex"
            />
          ))}
        </div>
      </div>
      <div className="w-full mt-[3em] align-center justify-center flex flex-col items-center justify-center">
        <div className="">
          <Typography
            className={"text-center mb-[1em]"}
            translationKey="HOME.FEATURES"
            variant="h2"
          />
          <div className="flex align-center items-center justify-center flex-wrap">
            {features.map(({ title, body }) => (
              <div className="md:w-[23em] mx-[2em] mb-[2em]" key={title}>
                <Typography
                  className={"text-center mb-[.5em]"}
                  translationKey="HOME.FEATURE"
                  variant="h4"
                  values={{ feature: title }}
                />
                <Typography
                  className={"text-center text-sm"}
                  translationKey="HOME.FEATURE"
                  variant="p"
                  values={{ feature: body }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full align-center my-[3em] flex items-center justify-center">
        {links.map((link) => (
          <Typography
            key={link}
            className={"text-center w-[10em] text-sm text-grey-200"}
            translationKey="HOME.LINK"
            values={{ link }}
            variant="h3"
          />
        ))}
      </div>
    </div>
  );
};
