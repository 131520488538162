import React from 'react'
import { ROUTE_PATHS } from './constants';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {

    if(this.props.gotToHome) {
      return window.location.replace(ROUTE_PATHS.HOME)
    }
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>We encountered an error</h1>
    }

    return this.props.children;
  }
}

export default ErrorBoundary