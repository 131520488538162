import { useParams } from "react-router-dom";
import {
  useGetTranscription,
  useApproveTranscription,
  useDownloadTranscription,
} from "../../../common";
import { Loader } from "react-feather";
import { Typography, Player } from "../../../component";
import { ACTIONS, JOBS_STATUS, MODAL_VIEWS, TRANSCRIPTION_STATUS } from "../../../constants";
import { useCallback, useEffect, useState } from "react";
import { toastUtil } from "../../../utils";
import Swal from "sweetalert2";
import { Modal } from "../../../component/modal/modal.component";
import { TranscriptionFeedback } from "../../../component/transcription-feedback";


export const ViewTranscription = () => {
  const { id } = useParams();
  const [transcription, setTranscription] = useState({});
  const [modalView, setModalView] = useState();
  const [modalIsOpen, setOpenModal] = useState(false);
  const { mutate, isLoading, isError } = useGetTranscription();
  const { mutate: mutateDownloadTranscription, isLoading: isDownloading } =
    useDownloadTranscription();
  const {
    mutate: mutateOnApprove,
    isLoading: approving,
    isError: isErrorApproving,
  } = useApproveTranscription();

  const fetchTranscription = useCallback(() => {
    mutate(id, {
      onSuccess: ({ data: { data } }) => {
        setTranscription(data);
      },
      onError: (err) =>
        toastUtil({
          message:
            err.response?.data?.message || "Unable to fetch transcription",
          theme: "failure",
        }),
    });
  }, [id, mutate]);

  useEffect(() => {
    fetchTranscription();
  }, [id, fetchTranscription]);

  const approveTranscription = () => {
    mutateOnApprove(id, {
      onSuccess: () => {
        toastUtil({
          message: "Successfully approved transcription",
          theme: "success",
          position: "top-center",
        });
        setTranscription({
          ...transcription,
          status: TRANSCRIPTION_STATUS.COMPLETED,
        });
      },
      onError: (error) => {
        toastUtil({
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : "Could not approve transcription",
          theme: "failure",
          position: "top-center",
        });
      },
    });
  };

  const onApprove = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Are you sure?",
      text: `Once approved, this can no longer be submitted for review`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I confirm!",
    });
    if (isConfirmed) {
      approveTranscription();
    }
  };

  const handleDownload = (response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;

    // Set the download attribute to the desired file name
    link.setAttribute("download", `${transcription.name}.srt`);

    // Append to the document and trigger download
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const onDownload = () => {
    mutateDownloadTranscription(id, {
      onSuccess: (response) => {
        handleDownload(response);
      },
      onError: (error) => {
        toastUtil({
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : "Could not download transcriptions",
          theme: "failure",
          position: "top-center",
        });
      },
    });
  };

  const toggleModal = () => {
    setOpenModal(!modalIsOpen);
  };

  const onReject = () => {
    setModalView(MODAL_VIEWS.ADD_FEEBACK);
    toggleModal();
  };

  const onConversationsClick = () => {
    setModalView(MODAL_VIEWS.CONVERSATIONS);
    toggleModal();
  };

  const updateConversationsInTranscription = (feedback) => {
    setTranscription({
      ...transcription,
      jobStatus: JOBS_STATUS.ASSIGNED,
      conversations: [
        ...transcription.conversations,
        {
          ...feedback,
          createdAt: new Date(),
        },
      ],
    });
  };

  const show = {
    noTranscription: !isLoading && !isError && !transcription,
    loader: isLoading || approving || isDownloading,
    error: isError || isErrorApproving,
    transcription: !isLoading && !isError && !!transcription,
  };

  return (
    <>
      {show.loader && (
        <div className="my-4 flex flex-col items-center">
          <Loader size={30} />
        </div>
      )}
      {show.error && (
        <div className="my-4 flex flex-col items-center">
          <Typography
            className="break-words"
            variant="h1"
            translationKey="VIEW_TRANSCRIPTION.ERROR_MESSAGE"
            values={{ errorMessage: show.errorMessage }}
          />
        </div>
      )}
      {show.transcription && (
        <Player
          onReject={onReject}
          videoUrl={transcription.url}
          name={transcription.name}
          isFreelancer={false}
          onConversationsClick={onConversationsClick}
          jobId={transcription.jobId}
          transcriptionList={transcription.subtitles}
          actions={[ACTIONS.DELETE]}
          onApprove={onApprove}
          onDownload={onDownload}
          conversations={transcription?.conversations}
          status={transcription.status}
          jobStatus={transcription.jobStatus}
          disabled={transcription.status !== TRANSCRIPTION_STATUS.COMPLETED}
          videoDuration={transcription.duration}
        />
      )}
      <Modal closeModal={toggleModal} isOpen={modalIsOpen}>
          <TranscriptionFeedback
            toggleModal={toggleModal}
            view={modalView}
            conversations={transcription?.conversations}
            updateTranscription={updateConversationsInTranscription}
          />
      </Modal>
    </>
  );
};
