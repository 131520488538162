import { Outlet } from "react-router-dom";
import { MenuLinks } from "../Header";
import { AnimationWrapper } from "../../component";
import { ROUTE_LABELS, ROUTE_PATHS, USER_ROLES } from "../../constants";
import { CloudRain } from "react-feather";
import { useAuth } from "../../core";

export const RouteWrapper = () => {
  const auth = useAuth();

  let routes = [];

  if (auth?.user?.role === USER_ROLES.freelancer) {
    routes = [
      ...routes,
      {
        to: ROUTE_PATHS.JOBS.LIST,
        label: ROUTE_LABELS.JOBS.LIST,
        icon: <CloudRain size={14} />,
      },
    ];
  }

  if (auth?.user?.role === USER_ROLES.user) {
    routes = [
      ...routes,
      {
        to: ROUTE_PATHS.TRANSCRIPTIONS.LIST,
        label: ROUTE_LABELS.TRANSCRIPTIONS.LIST,
        icon: <CloudRain size={14} />,
      },
    ];
  }

  return (
    <AnimationWrapper>
      <div className="flex">
        <div className="h-screen w-[200px] md:flex hidden bg-gray-100 drop-shadow py-[5.5em]">
          <ul className="flex mt-8 justify-start flex-col w-full lg:mx-2 px-2">
            <MenuLinks setShowSideMenu={() => {}} menuLinks={routes} />
          </ul>
        </div>
        <div
          animate={{ x: 100 }}
          transition={{ delay: 1 }}
          className="md:px-8 md:h-screen md:pt-[8em] pt-[8em] overflow-y-hidden px-4 w-full"
        >
          <Outlet />
        </div>
      </div>
    </AnimationWrapper>
  );
};
