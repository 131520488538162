import { Menu } from "react-feather";

import { MobileSlide } from "./mobile-slider.component";
import { TradesKonnectLogo } from "./trades-konnect.component";
import { Button } from "../../component";
import { useAuth } from "../../core";
import { useNavigate } from "react-router-dom";
import { ROUTE_LABELS, ROUTE_PATHS } from "../../constants";

const menuLinks = [
  {
    to: ROUTE_PATHS.TRANSCRIPTIONS.LIST,
    label: ROUTE_LABELS.TRANSCRIPTIONS.LIST,
  },
];

export const AuthedHeader = ({ showSideMenu, setShowSideMenu }) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    auth?.handleRemoveToken();
    navigate(ROUTE_PATHS.HOME);
  };

  return (
    <div className="h-[100px] py-6 bg-blue-600 px-2 lg:px-0 top-0 z-50 fixed w-full">
      <nav className="mx-auto h-full flex items-center md:px-8">
        <div className="flex items-center flex-1 mb-4 mt-4">
          <TradesKonnectLogo className="text-white" />
        </div>
        <button
          onClick={() => setShowSideMenu(!showSideMenu)}
          className="outline-none md:hidden"
        >
          <Menu size={30} className="pr-1" color="white" />
        </button>
        {auth.isAuthenticated && (
          <Button
            onClick={handleLogout}
            translationKey="LOGOUT"
            variant="primary"
            className="px-2 py-1 ml-12 hidden md:block"
          />
        )}
      </nav>
      <MobileSlide
        showlogButton={auth?.isAuthenticated}
        handleLogout={handleLogout}
        setShowSideMenu={setShowSideMenu}
        menuLinks={menuLinks}
        showSideMenu={showSideMenu}
      />
    </div>
  );
};
