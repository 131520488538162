import { MessageCircle } from "react-feather";
import { Typography } from "../typography";
import { Button } from "../button";

export const TranscriptionFeedbackButton = ({
  count = 0,
  onConversationsClick,
  onSubmit,
  onApprove,
  onDownload,
  onReject,
  isFreelancer,
  isApproved,
  isInReview,
}) => {
  return (
    <div className="justify-end mb-[1em] flex">
      <div className="flex align-center mr-[.5em]">
        <div>
          <button
            className={
              "p-[0.4em] flex align-center rounded-md text-sm md:text-md mr-[.3em]"
            }
            onClick={onConversationsClick}
          >
            <MessageCircle className="border-red-500" />
            <Typography
              translationKey="TRANSCRIPTION.FEEDBACK_COUNT"
              values={{ count }}
            />
          </button>
        </div>
      </div>
      {!isFreelancer && (
        <>
          {isApproved && (
            <div className="flex mr-[0.5em] align-center">
              <Button
                translationKey="TRANSCRIPTION.DOWNLOAD_FILE"
                onClick={onDownload}
                variant="blue"
              />
            </div>
          )}
          {isInReview && (
            <>
              <div className="flex mr-[0.2em] md:mr-[1em] align-center">
                <Button
                  translationKey="TRANSCRIPTION.APPROVE"
                  onClick={onApprove}
                  variant="blue"
                  className={"gap-[0] px-[1em] text-white no-outline"}
                />
              </div>
              <Button
                translationKey="TRANSCRIPTION.REJECT"
                onClick={onReject}
                variant="blue"
                className={
                  "gap-[0] bg-red-300 px-[1em] text-white outline-none border-red-300"
                }
              />
            </>
          )}
        </>
      )}

      {isFreelancer && (
        <Button
          translationKey="TRANSCRIPTION.SUBMIT"
          onClick={onSubmit}
          variant="blue"
        />
      )}
    </div>
  );
};
