import axios from "axios"

const baseApi = 'https://toscribe-35cc7daed1cd.herokuapp.com/api/v1'

export const apiRoutes = {
  login: '/auth/signin',
  register: '/auth/signup',
  googleLogin: `${baseApi}/auth/google`,
  transcriptions: `${baseApi}/transcriptions`,
  jobs: `${baseApi}/jobs`,
  freelancers: `${baseApi}/freelancers`,
  subtitles: `${baseApi}/subtitles`,
  uploadUrl: (resourceId, index) => `${baseApi}/files/url?resourceId=${resourceId}&index=${index}`,
  transactions: '/transactions'
}

  export const axiosInstance = axios.create({
    baseURL: baseApi,
    headers: {
      Authorization: localStorage.getItem('session')
    }
    
 })
