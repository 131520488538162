import { convertSecondsToTime } from "../../../utils";
import { Typography } from "../../typography";
import { PlayerNavigationControl } from "../assets/PlayerNavigationConrol";

export const PlayerControl = ({
  isPlaying,
  moveVideo,
  playVideo,
  playerRef,
  disabled,
  setUseDefaultInterval,
  useDefaultInterval,
}) => {
  return (
    <div className="player-controls">
      <div className="player-controls-navigation">
        <div className="flex flex-center">
          <PlayerNavigationControl
            type="backward"
            onClick={() => moveVideo("left")}
          />
          {!isPlaying && (
            <PlayerNavigationControl type="play" onClick={playVideo} />
          )}
          {isPlaying && (
            <PlayerNavigationControl type="pause" onClick={playVideo} />
          )}
          <PlayerNavigationControl onClick={() => moveVideo("right")} />
        </div>

        <Typography
          className={"text-xs"}
          variant="p"
          translationKey="PLAYER.CONTROLS.TIME"
          values={{
            time: convertSecondsToTime(playerRef?.current?.currentTime() || 0),
          }}
        />
        {/* {disabled && (
          <div className="player-control-interval">
            <input
              type="checkbox"
              className="mr-[5px]"
              onClick={() => setUseDefaultInterval(!useDefaultInterval)}
            />
            <Typography
              className={"text-xs"}
              variant="p"
              translationKey="PLAYER.CONTROLS.USE_DEFAULT"
            />
          </div>
        )} */}
      </div>
    </div>
  );
};
