import "./index.css";
import * as React from "react";
import { Button } from "../button";
import classNames from "classnames";

export const Modal = ({ children, closeModal, isOpen, header }) => {
  return (
    <div
      className={classNames(`modal-overlay`, {
        "modal-overlay__visible": isOpen,
      })}
    >
      <div className="modal-content">
        {header && <div className="modal-header">{header}</div>}
        {children}
        <div className="modal-footer">
          <Button
            translationKey="MODAL.CLOSE"
            variant={"blue"}
            onClick={closeModal}
            className={
              "gap-[0] px-[1em] bg-red-300 text-white outline-none border-red-300"
            }
          />
        </div>
      </div>
    </div>
  );
};
