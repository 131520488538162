import "./index.css";

const getControl = (type) => {
  switch (type) {
    case "forward":
      return (
        <svg
          className="navigation-control"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 423.642 423.642"
          xmlSpace="preserve"
        >
          <path
            d="M407.517,123.262l-27.717,11.48c18.585,44.869,18.585,94.29,0,139.159c-18.585,44.869-53.531,79.815-98.4,98.4
     c-92.627,38.368-199.194-5.776-237.559-98.4C8.46,188.486,43.246,91.212,121.514,46.501v74.992h30V7.498H37.519v30h43.755
     c-73,57.164-102.323,158.139-65.15,247.885c33.754,81.49,112.806,130.768,195.972,130.762c26.96-0.002,54.367-5.184,80.784-16.125
     C400.788,355.322,452.213,231.169,407.517,123.262z"
          />
        </svg>
      );
    case "play":
      return (
        <svg
          className="navigation-control"
          fill="#000000"
          viewBox="0 0 35 35"
          data-name="Layer 2"
          id="a57b1084-392d-4dd3-9105-7399e5d250c4"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.486,33.076a3.164,3.164,0,0,1-3.164-3.165V5.089A3.164,3.164,0,0,1,9.249,2.461L27.754,14.872a3.165,3.165,0,0,1,0,5.256L9.249,32.539h0A3.156,3.156,0,0,1,7.486,33.076ZM8.552,31.5h0ZM7.491,4.422a.7.7,0,0,0-.317.08.652.652,0,0,0-.352.587V29.911a.664.664,0,0,0,1.034.552L26.362,18.052a.66.66,0,0,0,.294-.553.646.646,0,0,0-.294-.55L7.856,4.537A.649.649,0,0,0,7.491,4.422Z" />
        </svg>
      );
    case "pause":
      return (
        <svg
        className="navigation-control"
          fill="#000000"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 92 92"
          xmlSpace="preserve"
        >
          <path
            id="XMLID_698_"
            d="M33,13v66c0,2.8-2.2,5-5,5s-5-2.2-5-5V13c0-2.8,2.2-5,5-5S33,10.2,33,13z M64,8c-2.8,0-5,2.2-5,5v66
	c0,2.8,2.2,5,5,5s5-2.2,5-5V13C69,10.2,66.8,8,64,8z"
          />
        </svg>
      );

    default:
      return (
        <svg
          className="navigation-control"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 423.754 423.754"
          xmlSpace="preserve"
        >
          <path
            d="M354.24,47.4l39.879-39.879H272.196v121.924l60.801-60.801c56,50.066,77.251,132.004,46.918,205.235
     c-18.585,44.869-53.531,79.815-98.4,98.4c-44.866,18.585-94.288,18.585-139.158,0c-44.869-18.585-79.815-53.531-98.4-98.4
     c-18.585-44.869-18.585-94.29,0-139.159l-27.717-11.48c-21.651,52.272-21.651,109.848,0,162.12
     c21.652,52.272,62.364,92.984,114.637,114.636c26.14,10.827,53.595,16.24,81.06,16.239c27.459-0.001,54.927-5.414,81.061-16.239
     c52.271-21.652,92.983-62.364,114.636-114.636C442.739,200.6,418.532,105.826,354.24,47.4z"
          />
        </svg>
      );
  }
};

export function PlayerNavigationControl({ type = "forward", onClick }) {
  return (
    <button className="navigation-control-btn mx-[10px]" onClick={onClick}>
      {getControl(type)}
    </button>
  );
}
