import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import { mergeClassName } from "../../utils";
import { typographyVariants } from "./typography.cva";

export const Typography = ({ variant, className, translationKey, values }) => {
  const Component = variant;

  return (
    <Component
      className={mergeClassName(typographyVariants({ variant }), className)}
    >
      <FormattedMessage values={values} id={translationKey} />
    </Component>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "label",
    "p",
    "span",
    "a",
  ]),
  className: PropTypes.string,
  translationKey: PropTypes.string.isRequired,
};

Typography.defaultProps = {
  variant: "body",
  color: "default",
};
